import Cookies from 'universal-cookie';
import _ from 'lodash';
import { fetchFingerPrintVisitorId } from 'services/fingerprint';

import RH from 'api/request-handler';

const cookies = new Cookies();
const AUTHCOOKIE = 'auth';

interface AuthHeaders {
  uid: string;
  client: string;
  accessToken: string;
}

let authHeadersInterceptor: any;

const setHeadersInterceptor = async (headers: AuthHeaders) => {
  const { uid, client, accessToken } = headers;
  const FPVisitorId = await fetchFingerPrintVisitorId();

  authHeadersInterceptor = RH.interceptors.request.use((config: any) => {
    const configClone = _.clone(config);

    configClone.headers.common['access-token'] = accessToken;
    configClone.headers.common['client'] = client;
    configClone.headers.common['uid'] = uid;
    configClone.headers.common['fingerprint-user-id'] = FPVisitorId;
    return config;
  });
};

export const initializeAuth = async (): Promise<boolean> => {
  const cookie = cookies.get(AUTHCOOKIE);

  if (cookie) {
    await setHeadersInterceptor(cookie);
    return true;
  }

  return false;
};

export const getAuthData = (): AuthHeaders => cookies.get(AUTHCOOKIE);

export const setAuthSession = async (headers: AuthHeaders) => {
  cookies.set(AUTHCOOKIE, headers, { path: '/' });
  await setHeadersInterceptor(headers);
};

export const removeAuthSession = () => {
  cookies.remove(AUTHCOOKIE);
  RH.interceptors.request.eject(authHeadersInterceptor);
};
