import React from 'react';
import { Modal as AntdModal } from 'antd';
import { ModalProps as ANTModalProps } from 'antd/lib/modal';

import './styles.less';

interface ModalProps extends ANTModalProps {
  open: boolean;
  setOpen: Function;
  size?: 'normal' | 'small';
  customFooter?: any;
}

interface CustomFooterProps {
  content: any;
}

const CustomFooter = (props: CustomFooterProps) => {
  const { content } = props;

  if (content) {
    return (
      <div className="custom-footer">
        {content}
      </div>
    );
  }
  return null;
};

const Modal = (props: React.PropsWithChildren<ModalProps>) => {
  const {
    open,
    setOpen,
    title,
    footer = null,
    customFooter = null,
    destroyOnClose = false,
    afterClose,
    size = 'small',
    children,
  } = props;

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <AntdModal
      title={title}
      visible={open}
      onCancel={handleCancel}
      footer={footer}
      destroyOnClose={destroyOnClose}
      afterClose={afterClose}
      width={size === 'normal' ? 720 : 520}
    >
      <div className="container">
        {children}
      </div>

      <CustomFooter content={customFooter} />
    </AntdModal>
  );
};

export default Modal;
