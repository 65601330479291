import React from 'react';
import { Row, Col } from 'antd';

import InfoForm from './info';
import PasswordForm from './password';

import './styles.less';

export default () => (
  <div className="wrapper">
    <Row gutter={[32, 32]}>
      <Col sm={12}>
        <InfoForm />
      </Col>
      <Col sm={12}>
        <PasswordForm />
      </Col>
    </Row>
  </div>
);
