import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from 'components/dashboard';
import Home from 'components/home';
import Courses from 'components/courses';
import Contact from 'components/contact';
import ResetPassword from 'components/reset-password';
import About from 'components/about';
import Course from 'components/course';
import Subject from 'components/subject';
import Mcq from 'components/mcq';
import PaymentOptions from 'components/purchase/payment-options';
import BankPayment from 'components/purchase/bank-payment';
import PaymentReturn from 'components/purchase/return';
import PaymentCancel from 'components/purchase/cancel';
import ContentPages from 'components/content';

import Registry from './registry';
import PrivateRoute from './private-route';
import RoutesGenerator, { Route as GeneratorRoutes } from './generator';
import WhatsappGroup from 'components/whatsapp-group';

const Routes = () => (
  <Switch>
    <Route exact path={Registry.Home.path} component={Home} />
    <Route exact path={Registry.PwdResetSuccess.path} component={Home} />
    <Route exact path={Registry.Courses.path} component={Courses} />
    <Route exact path={Registry.Courses.Course.path} component={Course} />
    <Route exact path={Registry.WhatsappGroup.path} component={WhatsappGroup} />
    <Route
      exact
      path={Registry.Courses.Course.Subject.path}
      component={Subject}
    />
    <Route exact path={Registry.Contact.path} component={Contact} />
    <Route exact path={Registry.ResetPassword.path} component={ResetPassword} />
    <Route exact path={Registry.About.path} component={About} />
    <Route
      exact
      key={RoutesGenerator(GeneratorRoutes.PrivacyPolicy)}
      path={RoutesGenerator(GeneratorRoutes.PrivacyPolicy)}
      component={ContentPages}
    />
    <Route
      exact
      key={RoutesGenerator(GeneratorRoutes.TermsAndConditions)}
      path={RoutesGenerator(GeneratorRoutes.TermsAndConditions)}
      component={ContentPages}
    />
    <PrivateRoute path={Registry.Dashboard.path} component={Dashboard} />
    <PrivateRoute
      path={Registry.Courses.Course.Subject.MCQ.path}
      component={Mcq}
    />
    <PrivateRoute
      path={RoutesGenerator(GeneratorRoutes.PaymentReturn)}
      component={PaymentReturn}
    />
    <PrivateRoute
      path={RoutesGenerator(GeneratorRoutes.PaymentCancel)}
      component={PaymentCancel}
    />
    <PrivateRoute
      path={RoutesGenerator(GeneratorRoutes.BankPayment)}
      component={BankPayment}
    />
    <PrivateRoute
      path={RoutesGenerator(GeneratorRoutes.PaymentOptions)}
      component={PaymentOptions}
    />
  </Switch>
);

export default Routes;
