export enum Route {
  // Payment
  PaymentOptions,
  BankPayment,
  PaymentReturn,
  PaymentCancel,

  // Dashboard
  Dashboard,

  // General
  Contact,
  ResetPassword,

  // Course
  Course,
  Subject,

  // Misc
  PrivacyPolicy,
  TermsAndConditions,
  WhatsappGroup,
}

export default (routeName: Route, params?: any) => {
  switch (routeName) {
    // Payment
    case Route.PaymentOptions:
      return params ? `/purchase/${params.type}/${params.id}` : '/purchase/:type/:id';
    case Route.BankPayment:
      return params ? `/purchase/${params.type}/${params.id}/bank` : '/purchase/:type/:id/bank';
    case Route.PaymentReturn:
      return params ? `/purchase/${params.type}/${params.id}/return` : '/purchase/:type/:id/return';
    case Route.PaymentCancel:
      return '/purchase/cancel';

    // Dashboard
    case Route.Dashboard:
      return '/dashboard';

    // General
    case Route.Contact:
      return '/contact';
    case Route.ResetPassword:
      return '/reset-password';

    // Course
    case Route.Course:
      return params ? `/courses/${params.id}` : '/courses/:id';
    case Route.Subject:
      return params ? `/courses/${params.courseId}/subjects/${params.subjectId}` : '/courses/:courseId/subject/:subjectId';

    // Misc
    case Route.PrivacyPolicy:
      return '/privacypolicy';

    case Route.TermsAndConditions:
      return '/terms';

    case Route.WhatsappGroup:
      return '/whatsapp_group'

    default:
      throw new Error('Invalid route name.');
  }
};
