import React from 'react';

import TitleArea from 'components/common/content/title';
import Container from 'components/common/section-container';
import { Tabs, TabPane } from 'components/common/tabs';

import landingBg from 'assets/images/home/home-landing-bg.jpg';

import Profile from './profile';
import MyCourses from './my-courses';
import MyOrders from './my-orders';

const Dashboard = (props: any) => {
  const { location } = props;

  const urlParams = new URLSearchParams(location.search);
  const tabParam = urlParams.get('tab');

  return (
    <>
      <TitleArea title="DASHBOARD" icon="courses" backgroundImg={landingBg} />
      <section className="section-container">
        <Container>
          <Tabs defaultActiveKey={tabParam ? tabParam : '1'} className="tabs">
            <TabPane tab="My Courses" key="1">
              <MyCourses />
            </TabPane>
            <TabPane tab="My Orders" key="2">
              <MyOrders />
            </TabPane>
            <TabPane tab="Profile" key="3">
              <Profile />
            </TabPane>
          </Tabs>
        </Container>
      </section>
    </>
  );
};

export default Dashboard;
