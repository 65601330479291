import React from 'react';

import './styles.less';

interface FieldWrapperProps {
  name: string | undefined;
  label?: string;
  error?: string | undefined;
  touched?: boolean | undefined;
}

const Error = (props: any) => {
  const { error, touched } = props;
  if (error && touched) {
    return <span className="error-message">{error}</span>;
  }
  return null;
};

const Input = (props: React.PropsWithChildren<FieldWrapperProps>) => {
  const {
    name,
    error,
    touched,
    label,
    children,
  } = props;

  return (
    <>
      <label className="input-label" htmlFor={name}>
        {label}
      </label>
      {children}
      <Error error={error} touched={touched} />
    </>
  );
};

export default Input;
