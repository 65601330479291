import React from 'react';

import './styles.less';

interface SectionContainerProps {
  className?: string;
  startFromTop?: boolean;
}

const SectionContainer = (props: React.PropsWithChildren<SectionContainerProps>) => {
  const { className = null, startFromTop = false, children } = props;

  return (
    <div className="container">
      <div className={`content-container ${startFromTop ? 'top' : ''} ${className}`}>
        {children}
      </div>
    </div>
  );
};

export default SectionContainer;
