import React from 'react';

import 'assets/fonts/fontello/fontello.css';

interface IconProps {
  name: 'down-open-big' | 'star' | string;
  size?: number;
}

const Icon = (props: IconProps & React.HTMLAttributes<HTMLDivElement>) => {
  const { name, size, className = '' } = props;
  return <i className={`icon-${name} ${className}`} style={{ fontSize: `${size}em` }} />;
};

export default Icon;
