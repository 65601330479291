import React from 'react';

import { Breadcrumb as BreadcrumbInterface } from 'components/common/breadcrumb/types';

import Icon from 'components/common/icon';
import Backdrop from 'components/common/backdrop';
import Breadcrumb from 'components/common/breadcrumb';

import './styles.less';

interface BackdropProps {
  className?: string;
  backgroundImg: string;
  title?: string;
  icon?: string;
  breadcrumbs?: BreadcrumbInterface[];
}

const Breadcrumbs = (props: any) => {
  const { breadcrumbs } = props;

  if (breadcrumbs) {
    return <Breadcrumb crumbs={breadcrumbs} />;
  }

  return null;
};

const Title = (props: BackdropProps) => {
  const {
    backgroundImg,
    title,
    icon,
    className = null,
    breadcrumbs,
  } = props;

  return (
    <Backdrop className={`content-title-area ${className}`} backgroundImg={backgroundImg}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      {icon ? (<Icon name={icon} className="content-title-icon" />) : null}
      {title ? (<h1>{title}</h1>) : null}
    </Backdrop>
  );
};

export default Title;
