import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';

import { CourseAPI } from 'api';

import TitleArea from 'components/common/content/title';
import { Heading } from 'components/common/typography';
import { Course } from 'components/common/course';
import Spinner from 'components/common/spinner';

import landingBg from 'assets/images/home/home-landing-bg.jpg';

import './styles.less';

const CourseItems = (props: any) => {
  const { courses } = props;

  return courses.map((course: any) => (
    <Col sm={12} lg={8}>
      <Course id={course.id} title={course.title} description={course.course_summary} />
    </Col>
  ));
};

const Category = (props: any) => {
  const { category: { title, courses } } = props;

  return (
    <section className="category-item">
      <Heading partialUnderline center>{title}</Heading>
      <Row gutter={[{ sm: 40 }, 40]}>
        <CourseItems courses={courses} />
      </Row>
    </section>
  );
};

const Categories = (props: any) => {
  const { data: { categories } } = props;
  return categories.map((cate: any) => <Category category={cate} />);
};

const Courses = () => {
  const [courses, setCourses] = useState(null);
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    const getCourses = async () => {
      setBusy(true);
      try {
        const res = await CourseAPI.courses();
        setCourses(res.content);
        setBusy(false);
      } catch (error) {
        setBusy(false);
        console.log(error);
      }
    };

    getCourses();
  }, []);

  return (
    <>
      <TitleArea backgroundImg={landingBg} title="COURSES" icon="courses" />
      <section className="courses-list">
        <div className="container">
          <Spinner busy={busy}>
            <Categories data={courses} />
          </Spinner>
        </div>
      </section>
    </>
  );
};

export default Courses;
