import React from 'react';
import { Button as AntButton } from 'antd';
import './styles.less';

interface ButtonProps {
  type?: any;
  children: any;
  onClick?: any;
  className?: string;
  invert?: boolean;
  fullWidth?: boolean;
  textOnly?: boolean;
  underline?: boolean;
  size?: 'small' | 'large';
  htmlType?: any;
  loading?: boolean;
  disabled?: boolean;
}

const Button = ({
  type = 'primary',
  invert = false,
  fullWidth = false,
  textOnly = false,
  underline = false,
  size,
  onClick,
  children,
  htmlType = 'button',
  className,
  loading = false,
  disabled = false,
}: ButtonProps) => {
  const classNames = ['button'];

  if (invert) {
    classNames.push('invert');
  }

  if (fullWidth) {
    classNames.push('full-width');
  }

  if (textOnly) {
    classNames.push('text-only');
  }

  if (underline) {
    classNames.push('underline');
  }

  if (size === 'small') {
    classNames.push('small');
  }

  if (size === 'large') {
    classNames.push('large');
  }

  return (
    <AntButton
      className={`${classNames.join(' ')} ${className}`}
      type={type}
      onClick={onClick}
      htmlType={htmlType}
      loading={loading}
      disabled={disabled}
    >
      {children}
    </AntButton>
  );
};

export default Button;
