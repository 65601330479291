import { useState } from 'react';
import { createContainer } from 'unstated-next';
import _ from 'lodash';

function useAuth(initiateState = null) {
  const [user, setUser] = useState<any>(initiateState);
  const [signInModal, setSignInModal] = useState<boolean>(false);
  const [signUpModal, setSignUpModal] = useState<boolean>(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState<boolean>(false);

  const login = (userData: any) => {
    setUser(userData);
  };

  const logout = () => {
    setUser(null);
  };

  const updateUser = (newData: any) => {
    const currentUser = _.clone(user);
    const updatedUser = _.assign(currentUser, newData);
    setUser(updatedUser);
  };

  return {
    login,
    logout,
    updateUser,
    user,

    // Modals
    signInModal,
    setSignInModal,
    signUpModal,
    setSignUpModal,
    forgotPasswordModal,
    setForgotPasswordModal,
  };
}

export default createContainer(useAuth);
