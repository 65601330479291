import React, { useEffect } from 'react';
import { Layout, Row, Col, Menu } from 'antd';
import { matchPath } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';

import logo from 'assets/images/logo.svg';

import { Button } from 'components/common/form';
import SignIn from 'components/common/header/user/sign-in';
import SignUp from 'components/common/header/user/sign-up';
import ForgotPassword from 'components/common/header/user/forgot-password';
import LangSelector from 'components/common/language-selector';

import RoutesRegistry from 'services/router/registry';
import * as AuthService from 'services/auth';

import UserContainer from 'containers/auth';

import { AuthModal } from './user/enums';

import './styles.less';

const SignInMenu = (props: any) => {
  const { user } = props;

  if (user) {
    return (
      <Menu.Item key="signOut">
        <span className="sign-out-btn">Sign Out</span>
        <Button className="sign-out-btn" size="small" textOnly>
          Sign Out
        </Button>
      </Menu.Item>
    );
  }

  return (
    <Menu.Item key="signIn">
      <span className="sign-in-btn">Sign In</span>
      <Button className="sign-in-btn" size="small">
        Sign In
      </Button>
    </Menu.Item>
  );
};

const NavLink = (history: any, path: string, label: string) => {
  const isActive = matchPath(history.location.pathname, {
    path,
  });
  return (
    <Menu.Item key={path} className={isActive ? 'active' : ''}>
      {label}
    </Menu.Item>
  );
};

const Header: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const {
    signInModal,
    setSignInModal,
    signUpModal,
    setSignUpModal,
    forgotPasswordModal,
    setForgotPasswordModal,
  } = userContainer;
  const history = useHistory();

  const closeAll = () => {
    setSignUpModal(false);
    setSignInModal(false);
    setForgotPasswordModal(false);
  };

  const setOpen = (type: AuthModal) => {
    switch (type) {
      case AuthModal.SignIn:
        closeAll();
        setSignInModal(true);
        break;
      case AuthModal.SignUp:
        closeAll();
        setSignUpModal(true);
        break;
      case AuthModal.ForgotPassword:
        closeAll();
        setForgotPasswordModal(true);
        break;
      default:
        closeAll();
    }
  };

  const handleClick = (e: any) => {
    const { key } = e;

    switch (key) {
      case 'signOut':
        AuthService.removeAuthSession();
        userContainer.logout();
        history.push(RoutesRegistry.Home.path);
        break;
      case 'signIn':
        setOpen(AuthModal.SignIn);
        break;
      default:
        history.push(key);
    }
  };

  useEffect(() => {
    const currentURL = window.location.href;
    if (currentURL.includes(RoutesRegistry.PwdResetSuccess.path)) {
      setSignInModal(true);
    }
  }, [])


  return (
    <StickyContainer>
      <Sticky bottomOffset={40} disableCompensation>
        {({ isSticky }) => (
          <Layout.Header className={`header ${isSticky ? '' : 'sticky'}`}>
            <div className="header-container">
              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="reset-button-padding"
              >
                <Col xs={20} sm={12}>
                  <Link to="/">
                    <img src={logo} alt="Logo" />
                  </Link>
                  <LangSelector />
                </Col>
                <Col xs={4} sm={12}>
                  <Menu
                    mode="horizontal"
                    onClick={handleClick}
                    className="menu"
                  >
                    {NavLink(history, RoutesRegistry.Courses.path, 'Courses')}
                    {NavLink(history, RoutesRegistry.About.path, 'About')}
                    {NavLink(history, RoutesRegistry.Contact.path, 'Contact')}
                    {userContainer.user
                      ? NavLink(
                          history,
                          RoutesRegistry.Dashboard.path,
                          'Dashboard',
                        )
                      : null}
                    {SignInMenu({ user: userContainer.user })}
                  </Menu>
                </Col>
              </Row>
            </div>
            <SignIn open={signInModal} setOpen={setOpen} />
            <SignUp open={signUpModal} setOpen={setOpen} />
            <ForgotPassword open={forgotPasswordModal} setOpen={setOpen} />
          </Layout.Header>
        )}
      </Sticky>
    </StickyContainer>
  );
};

export default Header;
