import React, { useState } from 'react';
import { Icon } from 'antd';

import { Button } from 'components/common/form';
import Modal from 'components/common/modal';

import UserContainer from 'containers/auth';

import './styles.less';

interface PurchaseButtonProps {
  id: number;
  label: string;
  hasPurchased: boolean;
  type: 'subject' | 'stage';
  purchase: (id: number) => void;
  goToStage?: () => void;
}

const PurchaseButton = (props: PurchaseButtonProps) => {
  const { id, label, hasPurchased, purchase, type, goToStage } = props;
  const userContainer = UserContainer.useContainer();
  const { setSignInModal } = userContainer;
  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);

  const clickHandler = () => {
    if (userContainer.user) {
      if (type === 'subject') {
        setShowDiscountModal(true);
      } else {
        purchase(id);
      }
    } else {
      setSignInModal(true);
    }
  };

  const DiscountModal = () => (
    <Modal
      open={showDiscountModal}
      setOpen={setShowDiscountModal}
      title={(
        <>
          <Icon type="exclamation-circle" theme="twoTone" className="purchase-btn__modal__icon" />
          Stages available at a discount!
        </>
      )}
      footer={(
        <>
          <Button onClick={() => { if (goToStage) goToStage(); }}>Show Me</Button>
          <Button onClick={() => purchase(id)} type="default">No Thanks</Button>
        </>
      )}
    >
      You can buy stages at a discounted price instead of individual subjects. Would you like to see discounted stages?
    </Modal>
  );

  if (!hasPurchased) {
    return (
      <>
        <Button size="large" onClick={() => clickHandler()}>
          {label}
        </Button>
        <DiscountModal />
      </>
    );
  }

  return null;
};

export default PurchaseButton;
