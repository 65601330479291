import React from 'react';

import NumberWComma from 'utils/number-w-commas';

import './styles.less';
import { Col, Row } from 'antd';

interface BankDetailsProps {
  bankDetails: any;
}

const BankDetails = (props: BankDetailsProps) => {
  const {
    bankDetails: {
      bank,
      branch,
      account_name,
      account_number,
      reference_number,
      installments,
      amount,
    },
  } = props;

  return (
    <div className="bank-details">
      <div>

      <Row>
        <Col>
          <ul>
            <li>Lurn.lk Bank Account Details</li>
          </ul>
        </Col>
      </Row>

      <br/>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12}>
            <ul>
              <li>Bank: <span>{bank}</span></li>
              <li>Branch: <span>{branch}</span></li>
              <li>Account Name: <span>{account_name}</span></li>
              <li>Account Number: <span>{account_number}</span></li>
            </ul>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <ul>
            <li>Bank: <span>Peoples Bank</span></li>
              <li>Branch: <span>Boralesgamuwa</span></li>
              <li>Account Name: <span> Lurn.lk</span></li>
              <li>Account Number: <span>348-2-001-8-0010395</span>
            </li>
          </ul>
        </Col>
      </Row>

      <br/>
      <Row>
        <Col>
          <ul>
            <li>Payment Details</li>
          </ul>
        </Col>
      </Row>

      <Row>
        <Col>
          <ul>
            <li>Reference Number: <span>{installments ? installments.reference_number : reference_number}</span></li>
            <li>Amount: <span>Rs. {NumberWComma(installments && installments.price ? installments.price : amount)}</span></li>
          </ul>
        </Col>
      </Row>
      </div>
    </div>
  );
};

export default BankDetails;
