import React, { useState, useEffect } from 'react';

import { PurchaseAPI } from 'api';

import TitleArea from 'components/common/content/title';
import { Breadcrumb } from 'components/common/breadcrumb/types';
import Container from 'components/common/section-container';
import Wrapper from 'components/common/wrappers/inner-wrapper';
import FSL from 'components/common/full-screen-loader';
import landingBg from 'assets/images/home/home-landing-bg.jpg';

import RoutesRegistry from 'services/router/registry';

import CompletePurchase from './complete-purchase';
import SubmitSlip from './submit-slip';

const crumbs: Breadcrumb[] = [
  RoutesRegistry.Courses,
  {
    title: RoutesRegistry.Courses.Course.title,
  },
  {
    title: 'Pay by bank transfer',
  },
];

const Course = (props: any) => {
  const { location } = props;
  const [bankDetails, setBankDetails] = useState<any>(null);
  const [paymentOptions, setPaymentOptions] = useState<any>({});
  const [busy, setBusy] = useState(true);
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const getBankDetails = async () => {
      try {
        if (query.get('ref')) {
          const res = await PurchaseAPI.getBankDetails({
            refNumber: query.get('ref'),
            paymentMethod: 'bank',
          });
          setBankDetails(res.content);
        } else {
          const options = query.get('options');
          const jsonObj = options ? JSON.parse(options) : {};
          setPaymentOptions(jsonObj);
          const res = await PurchaseAPI.getBankDetails(jsonObj);
          setBankDetails(res.content);
        }
        setBusy(false);
      } catch (error) {
        setBusy(false);
        console.log(error);
      }
    };

    getBankDetails();
  }, []);

  if (busy) {
    return <FSL />;
  }

  return (
    <>
      <TitleArea
        backgroundImg={landingBg}
        title="BANK DEPOSIT"
        icon="charted-acc"
        breadcrumbs={crumbs}
      />
      <section className="section-container">
        <Container>
          <Wrapper>
            {query.get('ref') ? (
              <SubmitSlip bankDetails={bankDetails} />
            ) : (
              <CompletePurchase bankDetails={bankDetails} options={paymentOptions} />
            )}
          </Wrapper>
        </Container>
      </section>
    </>
  );
};

export default Course;
