import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import AuthService from 'containers/auth';

const isAuthenticated = (user: any, Component: any, props: any) => {
  const { location } = props;

  if (user) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} />;
  }
  return (
    <Redirect
      to={{
        pathname: '/',
        state: { from: location },
      }}
    />
  );
};

const PrivateRoute = (props: RouteProps) => {
  const { component, ...rest } = props;
  const authService = AuthService.useContainer();

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(props) => isAuthenticated(authService.user, component, props)}
    />
  );
};

export default PrivateRoute;
