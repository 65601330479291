import React from 'react';

import { Radio } from 'components/common/form';

import './styles.less';

interface Answer {
  key: number;
  answer: string;
}

interface AnswerObject {
  question_id: number;
  answer: number;
  answer_format: number;
}

interface QuestionProps {
  id: number;
  format: number;
  question: string;
  answers: Answer[];
  selectedAnswer?: number;
  hasSubmitted?: boolean;
  isReview?: boolean;
  correctAnswer?: number;
  userAnswer?: number;
  onAnswer: (answer: AnswerObject) => void;
}

const Question = (props: QuestionProps) => {
  const {
    id,
    question,
    answers,
    format,
    selectedAnswer,
    hasSubmitted = false,
    isReview = false,
    correctAnswer,
    userAnswer,
    onAnswer,
  } = props;

  const handleChange = (e: any) => {
    onAnswer({
      question_id: id,
      answer: Number(e.currentTarget.value),
      answer_format: format,
    });
  };

  const answerComps = answers.map((answer: any) => (
    <Radio
      value={answer.key}
      name={`question${id}`}
      label={answer.answer}
      onChange={handleChange}
      key={answer.key}
      checked={answer.key === selectedAnswer}
      disabled={isReview}
      correctAnswer={correctAnswer}
      userAnswer={userAnswer}
    />
  ));

  return (
    <div className="question">
      <p>
        {question}
      </p>
      <form>
        {answerComps}
      </form>
    </div>
  );
};

export default Question;
