import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { GeneralAPI } from 'api';

import RoutesGenerator, { Route } from 'services/router/generator';

import TitleArea from 'components/common/content/title';
import FSL from 'components/common/full-screen-loader';

import landingBg from 'assets/images/home/home-landing-bg.jpg';

const Content = () => {
  const location = useLocation();
  const [busy, setBusy] = useState(true);
  const [data, setData] = useState<any>({});

  const init = async () => {
    try {
      setBusy(true);
      let content;
      if (location.pathname === RoutesGenerator(Route.PrivacyPolicy)) {
        content = await GeneralAPI.getPrivacyPolicy();
      } else {
        content = await GeneralAPI.getTermsAndConditions();
      }
      setData(content.content);
      setBusy(false);
    } catch (err) {
      setBusy(false);
      console.error(err);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const Comp = () => {
    if (busy) {
      return <FSL />;
    }

    return (
      <>
        <TitleArea backgroundImg={landingBg} title={data.title} icon="popular-learning-path" />
        <div className="section-container">
          <div className="container">
            <div className="content-container" dangerouslySetInnerHTML={{ __html: data.content }} />
          </div>
        </div>
      </>
    );
  };

  return <Comp />;
};

export default Content;
