import React from 'react';

import { Heading } from 'components/common/typography';

import './styles.less';

const WhyUs = (props: any) => {
  const { content: { title, description } } = props;

  return (
    <section className="section-container why-us-section">
      <div className="container">
        <Heading partialUnderline center inverted>{title}</Heading>
        <div className="content" dangerouslySetInnerHTML={{__html: description }} />
      </div>
    </section>
  );
};

export default WhyUs;
