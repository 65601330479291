import React from 'react';

import Icon from 'components/common/icon';

import './styles.less';

interface Feature {
  title: string;
  description: string;
}

interface FeaturesProps {
  content: Feature[];
}

interface FeatureProps {
  index: number;
  feature: Feature;
}

const iconList = [
  'easy-payment-plan',
  'expert-lecturers',
  'unlimited-access',
];

const Feature = ({ feature, index }: FeatureProps) => (
  <div className="feature-item">
    <div className="feature-item-container">
      <div className="feature-item__icon">
        <Icon name={iconList[index]} />
      </div>
      <div className="feature-content">
        <h3>{feature.title}</h3>
        <p>{feature.description}</p>
      </div>
    </div>
  </div>
);

const Features = (props: FeaturesProps) => {
  const { content } = props;
  const features = content.map((feature, index) => <Feature feature={feature} key={index} index={index} />);

  return (
    <section className="features section-container">
      <div className="container">
        <div className="features-container">
          {features}
        </div>
      </div>
    </section>
  );
};

export default Features;
