import React from 'react';

import './styles.less';

interface InnerWrapperProps {
  className?: string;
}

const InnerWrapper = (props: React.PropsWithChildren<InnerWrapperProps>) => {
  const { className, children } = props;
  return <div className={`${className || ''} inner-wrapper`}>{children}</div>;
};

export default InnerWrapper;
