import React from 'react';

import Icon from 'components/common/icon';

import './styles.less';

interface CategoryProps {
  category: any;
  onClick: (id: number) => void;
}

const Category = (props: CategoryProps) => {
  const { category, onClick } = props;

  return (
    <div className="category" style={{ backgroundImage: `url('${category.image}')` }} onClick={() => onClick(category.id)}>
      <div className="category__content">
        {/* <Icon name="popular-learning-path" /> */}
        <h3>{category.title}</h3>
      </div>
    </div>
  );
};

export default Category;
