import React from 'react';
import { Row, Col, Divider } from 'antd';

import TitleArea from 'components/common/content/title';
import Icon from 'components/common/icon';
import { Heading } from 'components/common/typography';

import landingBg from 'assets/images/home/home-landing-bg.jpg';

import ResetPasswordForm from './reset-password-form';

import './styles.less';

const ResetPassword = () => {
  return (
    <>
      <TitleArea
        backgroundImg={landingBg}
        title="Reset password!"
      />
      <section className="section-container contact">
        <div className="container">
          <div className="contact__container">
            <Row gutter={[24, 24]} type="flex" className="contact__row">
            <Col xs={2} lg={8}>
            </Col>
            <Col xs={20} lg={8}>
              <div className="contact__content">
                <ResetPasswordForm />
              </div>
            </Col>
              <Col xs={2} lg={8}>

              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
