import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import Slider from 'react-slick';

import { GeneralAPI } from 'api';

import TitleArea from 'components/common/content/title';
import { Heading } from 'components/common/typography';
import FSL from 'components/common/full-screen-loader';

import landingBg from 'assets/images/home/home-landing-bg.jpg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './styles.less';

const Story = (props: any) => {
  const {
    data: { title, description, image },
  } = props;

  return (
    <section className="section-container about-us-section">
      <div className="container">
        <Heading center partialUnderline>
          {title}
        </Heading>
        <Row
          type="flex"
          gutter={[24, 24]}
          className="about-us-section__content"
        >
          <Col md={12}>
            <img src={image.url} alt="Our Story" />
          </Col>
          <Col md={12}>
            <div className="about-us-section__desc">
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

const Lecturers = (props: any) => {
  const { data } = props;
  const settings = {
    autoplay: true,
    infinite: true,
    speed: 500,
    rows: 2,
    slidesPerRow: 4,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          rows: 2,
          slidesPerRow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          rows: 2,
          slidesPerRow: 2,
        },
      },
    ],
  };

  const slides = data.map((lecturer: any) => {
    const { name, image, speciality } = lecturer;
    return (
      <div className="slide-item" key={name}>
        <div className="slide-item__header">
          <div
            className="lecturer-img"
            style={{ backgroundImage: `url(${image})` }}
          />
        </div>
        <div className="slide-item__footer">
          <span className="slide-item__name">{name}</span>
          <span className="slide-item__specialty">{speciality}</span>
        </div>
      </div>
    );
  });

  return (
    <section className="section-container lecturers-section">
      <div className="container">
        <Heading center partialUnderline>
          Our Lecturers
        </Heading>
        <Slider {...settings}>{slides}</Slider>
      </div>
    </section>
  );
};

const About = () => {
  const [busy, setBusy] = useState(true);
  const [data, setData] = useState<any>({});

  const init = async () => {
    try {
      setBusy(true);
      const { content } = await GeneralAPI.about();
      setData(content);
      setBusy(false);
    } catch (err) {
      setBusy(false);
      console.error(err);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const Comp = () => {
    if (busy) {
      return <FSL />;
    }
    return (
      <>
        <TitleArea backgroundImg={landingBg} title="ABOUT US" icon="courses" />
        <Story data={data.about_us} />
        <Lecturers data={data.lecturers} />
      </>
    );
  };

  return <Comp />;
};

export default About;
