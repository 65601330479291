import React from 'react';
import { useHistory } from 'react-router-dom';

import Icon from 'components/common/icon';
import { Button } from 'components/common/form';

import Registry from 'services/router/registry';

import { truncate } from 'utils';

import './styles.less';

interface CourseProps {
  id: number;
  title: string;
  description: string;
}

const Course = (props: CourseProps) => {
  const { id, title, description } = props;

  const history = useHistory();

  return (
    <div className="course">
      <div className="course-header">
        <h5>Course</h5>
        <h3>{truncate(title, 55)}</h3>
        <Icon name="graduation-hat" className="course-icon" />
      </div>
      <div className="course-body">
        <p>{description ? truncate(description, 135) : null}</p>
        <Button onClick={() => history.push(`${Registry.Courses.path}/${id}`)} fullWidth>
          EXPLORE
        </Button>
      </div>
    </div>
  );
};

export default Course;
