import React from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

import { Heading } from 'components/common/typography';
import {
  Subject,
  SubjectMoreDetails,
} from 'components/common/course';

import RoutesRegistry from 'services/router/registry';

import './styles.less';

const PopularSubjects = (props: any) => {
  const { content } = props;
  const history = useHistory();

  const subjects = content.map((sub: any) => (
    <Col xs={24} sm={12} md={12} lg={8} xl={8} key={sub.id}>
      <Subject
        subject={sub}
        onClick={() => history.push(`${RoutesRegistry.Courses.path}/${sub.course.id}/subjects/${sub.id}`)}
        moreDetails={(
          <SubjectMoreDetails
            courseName={sub.course.title}
            stageName={sub.stage.title}
            duration={sub.lecture_hours}
          />
        )}
      />
    </Col>
  ));

  return (
    <section className="popular-learning-path section-container">
      <div className="container">
        <Heading partialUnderline center>Popular Subjects</Heading>
        <Row gutter={[32, 32]}>
          {subjects}
        </Row>
      </div>
    </section>
  );
};

export default PopularSubjects;
