import React from 'react';
import { Collapse } from 'antd';

import './index.less';

const { Panel } = Collapse;

interface CollapsibleItem {
  title: string;
  body: JSX.Element | string;
  className?: string;
}

interface CollapsibleProps {
  content: CollapsibleItem[];
  expand?: 'all' | number[] | string[] | number;
  className?: string;
}

const Collapsible = (props: CollapsibleProps) => {
  const { content, expand, className = '' } = props;
  const defaultKey = expand === 'all' ? content.map((item: any) => item.title) : '';

  const items = content.map((item: any) => {
    const { title, body, className: itemClassName = '' } = item;
    return (
      <Panel header={title} key={title} className={`collapsible__item ${itemClassName}`}>
        {body}
      </Panel>
    );
  });

  return (
    <Collapse expandIconPosition="right" className={`collapsible__container ${className}`} defaultActiveKey={defaultKey}>
      {items}
    </Collapse>
  );
};

export default Collapsible;
