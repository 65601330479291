import React from 'react';
import { Input as ANTInput } from 'antd';
import { InputProps as ANTInputProps } from 'antd/lib/input';

import Icon from 'components/common/icon';
import FieldWrapper from '../field-wrapper';

import './styles.less';

interface InputProps extends ANTInputProps {
  label?: string;
  field?: object;
  error?: string | undefined;
  touched?: boolean | undefined;
}

const RateSelector = (props: InputProps) => {
  const {
    name,
    placeholder,
    onChange,
    onBlur,
    error,
    touched,
    value,
    label,
    disabled = false,
    className,
  } = props;

  const raters = [5, 4, 3, 2, 1].map((rater: number) => {
    const id = `${name}-${rater}`;
    return (
      <React.Fragment key={id}>
        <ANTInput
          id={id}
          type="radio"
          name={name}
          className={`input ${className}`}
          placeholder={placeholder}
          value={rater}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          checked={rater === Number(value)}
        />
        <label htmlFor={id}>
          <Icon name="star" />
        </label>
      </React.Fragment>
    );
  });

  return (
    <FieldWrapper name={name} label={label} touched={touched} error={error}>
      <div className="rate-selector">
        {raters}
      </div>
    </FieldWrapper>
  );
};

export default RateSelector;
