const RootRoutes = {
  Courses: '/courses',
  Purchase: '/purchase',
};

const RoutesRegistry = {
  Home: {
    path: '/',
  },
  PwdResetSuccess: {
    path: '/password/reset/success',
  },
  Contact: {
    path: '/contact',
  },
  ResetPassword: {
    path: '/reset-password',
  },
  About: {
    path: '/about',
  },
  Dashboard: {
    path: '/dashboard',
  },
  Courses: {
    path: RootRoutes.Courses,
    title: 'All Courses',

    Course: {
      path: `${RootRoutes.Courses}/:id`,
      title: 'Course Details',

      Subject: {
        path: `${RootRoutes.Courses}/:cid/subjects/:sid`,
        title: 'Subject Details',

        MCQ: {
          path: `${RootRoutes.Courses}/:cid/subjects/:sid/mcq/:mcqid`,
          title: 'MCQ',
        },
      },
    },
  },
  Purchase: {
    path: '/purchase/:type/:id',
    title: 'Payment Options',
  },
  WhatsappGroup: {
    path: '/whatsapp_group',
  }
};

export default RoutesRegistry;
