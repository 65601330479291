import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';

import Spinner from 'components/common/spinner';

import { CourseAPI } from 'api';

import ReviewsList from './reviews';
import Review from './review';

import './styles.less';

interface ReviewsProps {
  id: number;
  hasPurchased: boolean;
}

const Reviews = (props: ReviewsProps) => {
  const { id, hasPurchased } = props;
  const pageSize = 5;
  const [reviews, setReviews] = useState(null);
  const [busy, setBusy] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [fetchingReviews, setFetchingReviews] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchReviews = async (page = 1) => {
    try {
      setFetchingReviews(true);
      const res = await CourseAPI.getReviews(id, page, pageSize);
      setTotalItems(res.content.total_records);
      setCurrentPage(res.content.page);
      setReviews(res.content.reviews);
    } catch (error) {
      console.error(error);
    } finally {
      setFetchingReviews(false);
    }
  };

  const loadReviews = async () => {
    await fetchReviews();
    setBusy(false);
  };

  useEffect(() => {
    loadReviews();
  }, [id]);

  const submitReview = async (payload: any) => {
    const res = await CourseAPI.publishReview(id, payload);
    fetchReviews();
    return res;
  };

  if (busy) {
    return <Spinner />;
  }

  return (
    <Row gutter={[24, 0]}>
      <Col lg={17}>
        <ReviewsList
          reviews={reviews}
          fetchReviews={fetchReviews}
          totalItems={totalItems}
          currentPage={currentPage}
          isFetchingReviews={fetchingReviews}
          pageSize={pageSize}
        />
      </Col>
      <Col lg={7}>
        <Review hasPurchased={hasPurchased} submitReview={submitReview} />
      </Col>
    </Row>
  );
};

export default Reviews;
