import React, { useState } from 'react';
import { Row, Col, Upload, Button as AntButton, Icon, message } from 'antd';
import { useHistory } from 'react-router-dom';

import * as Form from 'components/common/form';
import { Heading } from 'components/common/typography';

import { PurchaseAPI } from 'api';

import RoutesGenerator, { Route } from 'services/router/generator';

import BankDetails from '../bank-details';

import './styles.less';

const { Button } = Form;

interface SubmitSlipProps {
  bankDetails: any;
}

const SubmitSlip = (props: SubmitSlipProps) => {
  const history = useHistory();
  const { bankDetails } = props;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [file, setFile] = useState<any>([]);

  const handleClick = async () => {
    try {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append('reference_number', bankDetails.bank_details.installments.reference_number);
      formData.append('id', bankDetails.bank_details.installments.id);
      formData.append('image', file[0].originFileObj);
      await PurchaseAPI.submitDepositSlip(formData);
      setIsSubmitting(false);
      message.success('Successfully submitted the deposit slip.');
      history.push(`${RoutesGenerator(Route.Dashboard)}?tab=2`);
    } catch (error) {
      setIsSubmitting(true);
      console.error(error);
    }
  };

  const uploadProps = {
    accept: 'image/*',
    beforeUpload() {
      return false;
    },
    onChange(info: any) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      setFile(fileList);
    },
  };

  return (
    <div className="complete-purchase">
      <Row gutter={24} type="flex">
        <Col md={24}>
          <Heading type={3}>Submit the transaction details</Heading>
          <p>Step 1: Fill the cash deposit slip using the below details.</p>
          <BankDetails bankDetails={bankDetails.bank_details} />
          <p>Step 2: Once you complete the bank transfer, please take a clear photo of the deposit slip, attach it using the below button and submit.</p>
          <p>It will take few days for the admins to verify and approve your payment. You will be able to access your course once the payment has been approved.</p>
        </Col>
        <Col md={8}>
          <div>
            <Upload {...uploadProps} fileList={file}>
              <AntButton>
                <Icon type="upload" />
                Click to Upload Deposit Slip
              </AntButton>
            </Upload>
          </div>
        </Col>
        <Col md={24}>
          <Button size="large" className="next-btn" onClick={handleClick} loading={isSubmitting} disabled={file.length < 1}>
            SUBMIT DEPOSIT SLIP
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SubmitSlip;
