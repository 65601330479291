import React from 'react';

import Rating from 'components/common/course/rating';
import Price from 'components/common/course/price';

import './styles.less';

interface SubjectProps {
  subject: any;
  onClick?: () => void;
  moreDetails?: React.ReactElement;
}

const Subject = (props: SubjectProps) => {
  const { subject, onClick, moreDetails } = props;

  const onPress = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className="subject"
      onClick={onPress}
      onKeyPress={onPress}
      role="link"
      tabIndex={0}
    >
      <div className="thumb-container">
        <img src={subject.image.url ? subject.image.url : subject.image} alt="Subject thumbnail" />
        {moreDetails ? (
          <div className="subject__more-details">
            {moreDetails}
          </div>
        ) : null}
      </div>
      <div className="content">
        <h3>{subject.title}</h3>
        <div className="sub-content">
          <Rating rating={subject.rate ? subject.rate : subject.rating} count={subject.rating_count} className="rating" />
          <Price price={subject.original_price} discountedPrice={subject.discounted_price} />
        </div>
      </div>
    </div>
  );
};

export default Subject;
