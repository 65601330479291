import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';

import { GeneralAPI } from 'api';

import TitleArea from 'components/common/content/title';
import { Heading } from 'components/common/typography';
import FSL from 'components/common/full-screen-loader';
import { Button } from 'antd';

import landingBg from 'assets/images/home/home-landing-bg.jpg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './styles.less';

const Story = (props: any) => {
  const {
    data: { group_link, description, image },
  } = props;


  return (
    <section className="section-container about-us-section">
      <div className="container">
        <Heading center partialUnderline>   </Heading>
        <Row
          type="flex"
          gutter={[24, 24]}
          className="about-us-section__content"
        >
          <Col md={12}>
            <img src={image.resized.url} alt="Our Story" />
          </Col>
          <Col md={12}>
            <div className="about-us-section__desc">
              <div dangerouslySetInnerHTML={{ __html: description }} />
              <Button children={`Join Now`} href={group_link} type='primary' target='_blank'></Button>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

const WhatsappGroup = () => {
  const [busy, setBusy] = useState(true);
  const [data, setData] = useState<any>({});

  const init = async () => {
    try {
      setBusy(true);
      const { content } = await GeneralAPI.whatsappGroup();
      setData(content);
      setBusy(false);
    } catch (err) {
      setBusy(false);
      console.error(err);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const Comp = () => {
    if (busy) {
      return <FSL />;
    }
    return (
      <>
        <TitleArea backgroundImg={landingBg} title="WhatsApp Group" icon="courses" />
        <Story data={data} />
      </>
    );
  };

  return <Comp />;
};

export default WhatsappGroup;
