import React, { useState } from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import { message } from 'antd';

import Alert from 'components/common/alert';
import * as Form from 'components/common/form';

import UserContainer from 'containers/auth';

import { UserAPI } from 'api';

import { regex } from 'utils';

const { Input, Button } = Form;

const infoFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name cannot be blank,'),
  lastName: Yup.string().required('Last name cannot be blank,'),
  phone: Yup.string()
    .matches(regex.phone, 'Please enter a valid phone number')
    .required('Please fill in your phone number'),
});

export default () => {
  const userContainer = UserContainer.useContainer();
  const {
    email, first_name, last_name, mobile_number, address, city,
  } = userContainer.user;
  const initialValues = {
    firstName: first_name, lastName: last_name, email, phone: mobile_number, address, city,
  };
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <>
      <Alert message={errorMessage} type="error" />
      <Formik
        initialValues={initialValues}
        validationSchema={infoFormSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setErrorMessage(null);
            const userData = {
              first_name: values.firstName,
              last_name: values.lastName,
              mobile_number: values.phone,
              address: values.address,
              city: values.city,
            };
            await UserAPI.updateMemberProfile(userData);
            userContainer.updateUser(userData);
            setSubmitting(false);
            message.success('User information updated.');
          } catch (error) {
            setErrorMessage('Something went wrong. Please try again.');
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <FormikForm noValidate>
            <Form.Item>
              <Field
                type="email"
                name="email"
                label="Email"
                as={Input}
                disabled
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="text"
                name="firstName"
                label="First Name"
                disabled={isSubmitting}
                error={errors.firstName}
                touched={touched.firstName}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="text"
                name="lastName"
                label="Last Name"
                disabled={isSubmitting}
                error={errors.lastName}
                touched={touched.lastName}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="tel"
                name="phone"
                label="Mobile Number*"
                disabled={isSubmitting}
                error={errors.phone}
                touched={touched.phone}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="text"
                name="address"
                label="Address"
                disabled={isSubmitting}
                error={errors.address}
                touched={touched.address}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="text"
                name="city"
                label="City"
                disabled={isSubmitting}
                error={errors.city}
                touched={touched.city}
                as={Input}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              className="action-btn"
              loading={isSubmitting}
            >
              SAVE
            </Button>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};