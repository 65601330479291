import React, { useState } from 'react';

import VideoModal from 'components/common/video-modal';
import { Button } from 'components/common/form';
import Backdrop from 'components/common/backdrop';

import landingBg from 'assets/images/home/home-landing-bg.jpg';

import './styles.less';

interface LandingContent {
  title: string;
  description: string;
  attachment_url: string;
}
interface LandingContentProps {
  content: LandingContent;
}

const Landing = ({ content }: LandingContentProps) => {
  const { title, description, attachment_url } = content;
  const [open, setOpen] = useState(false);

  return (
    <Backdrop backgroundImg={landingBg} className="landing" downArrow>
      <h1>{title}</h1>
      <h3>{description}</h3>
      <Button onClick={() => setOpen(true)}>HOW IT WORKS</Button>
      <VideoModal open={open} setOpen={setOpen} url={attachment_url} />
    </Backdrop>
  );
};

export default Landing;
