import React, { useState } from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';

import * as Form from 'components/common/form';
import Alert from 'components/common/alert';

import { GeneralAPI } from 'api';

const { Input, Button, TextArea } = Form;

const contactFormSchema = Yup.object().shape({
  name: Yup.string().required('Please enter your name.'),
  email: Yup.string()
    .email('Invalid email address.')
    .required('Email cannot be blank.'),
  phone: Yup.string().required('Please enter your phone.'),
  subject: Yup.string().required('Subject is required.'),
  message: Yup.string().required('Message cannot be blank.'),
});

const ContactForm = () => {
  const initialValues = { name: '', email: '', phone: '', subject: '', message: '' };
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  return (
    <>
      <Alert message={errorMessage} type="error" />
      <Alert message={successMessage} type="success" />
      <Formik
        initialValues={initialValues}
        validationSchema={contactFormSchema}
        onSubmit={async (
          { name, email, subject, phone, message },
          { setSubmitting, resetForm },
        ) => {
          try {
            setErrorMessage(null);
            setSuccessMessage(null);
            await GeneralAPI.contact({
              name,
              email,
              subject,
              phone,
              message
            });
            setSubmitting(false);
            resetForm();
            setSuccessMessage(
              'We have received your message. We will contact you shortly!',
            );
          } catch (error) {
            setErrorMessage('Something went wrong. Please try again.');
            setSuccessMessage(null);
            console.error(error);
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <FormikForm noValidate>
            <Form.Item>
              <Field
                type="name"
                name="name"
                label="Name*"
                disabled={isSubmitting}
                error={errors.name}
                touched={touched.name}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="email"
                name="email"
                label="Email*"
                disabled={isSubmitting}
                error={errors.email}
                touched={touched.email}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="phone"
                name="phone"
                label="Contact Number*"
                disabled={isSubmitting}
                error={errors.phone}
                touched={touched.phone}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="subject"
                name="subject"
                label="Subject*"
                disabled={isSubmitting}
                error={errors.subject}
                touched={touched.subject}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="message"
                name="message"
                label="Message*"
                disabled={isSubmitting}
                error={errors.message}
                touched={touched.message}
                as={TextArea}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              className="action-btn"
              loading={isSubmitting}
            >
              SUBMIT
            </Button>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};

export default ContactForm;
