import RH from './request-handler';
import { Response } from './request.interface';

interface Review {
  message: string;
  rating: number;
}

const courses = async (): Promise<Response> => {
  const res = await RH.get('/courses');
  return res.data;
};

const course = async (id: number): Promise<Response> => {
  const res = await RH.get(`/course/details?course_id=${id}`);
  return res.data;
};

const subject = async (id: number): Promise<Response> => {
  const res = await RH.get(`/subject?subject_id=${id}`);
  return res.data;
};

const getSyllabus = async (id: number, language: string): Promise<Response> => {
  const res = await RH.get(`/subject/${id}/syllabus?syllabus_lan=${language}`);
  return res.data;
};

const getLiveSessions = async (id: number, language: string): Promise<Response> => {
  const res = await RH.get(`/subject/${id}/live_sessions?syllabus_lan=${language}`);
  return res.data;
};

const getLecturers = async (subjectId: number): Promise<Response> => {
  const res = await RH.get(`/subject/${subjectId}/lecturers`);
  return res.data;
};

const publishReview = async (subjectId: number, payload: Review): Promise<Response> => {
  const res = await RH.post('/review', {
    ...payload,
    subject_id: subjectId,
  });
  return res.data;
};

const getReviews = async (subjectId: number, pageNumber: number, perPage: number): Promise<Response> => {
  const res = await RH.get(`/reviews?subject_id=${subjectId}&page=${pageNumber}&per_page=${perPage}`);
  return res.data;
};

const getMCQ = async (mcqId: number, language: string | null): Promise<Response> => {
  const res = await RH.get(`/subject/mcq/${mcqId}?mcq_lan=${language}`);
  return res.data;
};

const submitMCQ = async (mcqId: number, answers: any): Promise<Response> => {
  const res = await RH.post(`/subject/mcq/${mcqId}`, {
    answers: JSON.stringify(answers),
  });
  return res.data;
};

const getCourseInfo = async (type: string, id: number): Promise<Response> => {
  const res = await RH.get(`/syllabus/info?stage_or_subject=${type}&stage_or_subject_id=${id}`);
  return res.data;
};

export default {
  courses,
  course,
  subject,
  getSyllabus,
  getLiveSessions,
  publishReview,
  getReviews,
  getMCQ,
  submitMCQ,
  getCourseInfo,
  getLecturers,
};
