import React from 'react';
import { Result } from 'antd';
import { useHistory } from 'react-router-dom';

import TitleArea from 'components/common/content/title';
import Container from 'components/common/section-container';
import Wrapper from 'components/common/wrappers/inner-wrapper';
import { Button } from 'components/common/form';

import RoutesGenerator, { Route } from 'services/router/generator';

import landingBg from 'assets/images/home/home-landing-bg.jpg';

const Course = (props: any) => {
  const history = useHistory();
  const { location } = props;
  const query = new URLSearchParams(location.search);

  const goToDashboard = () => {
    history.push(RoutesGenerator(Route.Dashboard));
  };

  const message = query.get('message') ? query.get('message') : 'Something went wrong. Please try again.';

  return (
    <>
      <TitleArea
        backgroundImg={landingBg}
        title="ONLINE PAYMENT"
        icon="charted-acc"
      />
      <section className="section-container">
        <Container>
          <Wrapper>
            <Result
              status="error"
              title="Payment Failed"
              subTitle={message}
              extra={[
                <Button key="console" onClick={goToDashboard}>
                  Go To Dashboard
                </Button>,
              ]}
            />
          </Wrapper>
        </Container>
      </section>
    </>
  );
};

export default Course;
