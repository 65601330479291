import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form as FormikForm, Field } from 'formik';

import Alert from 'components/common/alert';
import * as Form from 'components/common/form';
import { Heading } from 'components/common/typography';

import './styles.less';

const { Button, TextArea, RateSelector } = Form;

interface ReviewProps {
  hasPurchased: boolean;
  submitReview: (payload: any) => void;
}

const reviewSchema = Yup.object().shape({
  message: Yup.string().required('Review cannot be blank.'),
  rating: Yup.number().moreThan(0, 'Please give a rating to your review.'),
});

const Review = (props: ReviewProps) => {
  const { hasPurchased, submitReview } = props;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const initialValues = {
    message: '',
    rating: 0,
  };

  if (!hasPurchased) {
    return (
      <div className="review-container no-review">
        You need to own this subject to leave reviews.
      </div>
    );
  }

  return (
    <div className="review-container">
      <Heading type={4}>Leave a Review</Heading>
      <Alert message={errorMessage} type="error" />
      <Formik
        initialValues={initialValues}
        validationSchema={reviewSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const signUpData = {
            message: values.message,
            rating: values.rating,
          };
          try {
            setErrorMessage(null);
            await submitReview(signUpData);
            resetForm();
            setSubmitting(false);
          } catch (error) {
            console.error(error);
            setErrorMessage('Something went wrong. Please try again.');
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <FormikForm noValidate>
            <Form.Item>
              <Field
                name="rating"
                label="Rating"
                disabled={isSubmitting}
                error={errors.rating}
                touched={touched.rating}
                as={RateSelector}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="text"
                name="message"
                label="Review"
                disabled={isSubmitting}
                error={errors.message}
                touched={touched.message}
                as={TextArea}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                loading={isSubmitting}
                fullWidth
              >
                SUBMIT
              </Button>
            </Form.Item>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default Review;
