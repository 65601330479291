import React from 'react';

import Icon from 'components/common/icon';

import './styles.less';

interface BackdropProps {
  className?: string;
  backgroundImg: string;
  downArrow?: boolean;
}

const Backdrop = (props: React.PropsWithChildren<BackdropProps>) => {
  const { 
    backgroundImg,
    downArrow = false,
    className = null,
    children,
  } = props;

  const arrow = () => {
    if (downArrow) {
      return <Icon name="down-open-big" className="icon" />;
    }
    return null;
  };

  return (
    <section className={`backdrop ${className}`} style={{ backgroundImage: `url(${backgroundImg})` }}>
      <div className="content">
        {children}
      </div>
      {arrow()}
      <div className="bg-gradient" />
    </section>
  );
};

export default Backdrop;
