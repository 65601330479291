import React, { useState } from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { regex } from 'utils';

import Modal from 'components/common/modal';
import * as Form from 'components/common/form';
import Alert from 'components/common/alert';

import UserContainer from 'containers/auth';

import { UserAPI } from 'api';

import RoutesRegistry from 'services/router/registry';
import * as AuthService from 'services/auth';
import { fetchFingerPrintVisitorId } from 'services/fingerprint';

import { AuthModal } from './enums';

import './styles.less';

const { Input, Button } = Form;

interface SignUpProps {
  open: boolean;
  setOpen: Function;
}

const SignUpFooter = (props: any) => {
  const { setOpen } = props;

  return (
    <div className="footer-links">
      <div>
        Already have an account? &nbsp;
        <Button textOnly underline onClick={() => setOpen(AuthModal.SignIn)}>
          Sign In
        </Button>
      </div>
    </div>
  );
};

const signUpSchema = Yup.object().shape({
  firstName: Yup.string().required('Please fill your first name.'),
  lastName: Yup.string().required('Please fill your last name.'),
  phone: Yup.string()
    .matches(regex.phone, 'Please enter a valid phone number')
    .required('Please fill in your phone number'),
  email: Yup.string()
    .email('Invalid email address.')
    .required('Email cannot be blank.'),
  password: Yup.string().required('Please enter your password.'),
  confirmPassword: Yup.string()
    .required('Please enter the password again.')
    .test('passwords-match', 'Passwords does not match.', function (value) {
      return this.parent.password === value;
    }),
});

const SignUp = (props: SignUpProps) => {
  const { open, setOpen } = props;
  const authService = UserContainer.useContainer();
  const [errorMessage, setErrorMesage] = useState(null);
  const history = useHistory();
  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    password: '',
    confirmPassword: '',
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Sign Up and Start Learning!"
      customFooter={<SignUpFooter setOpen={setOpen} />}
      destroyOnClose
      afterClose={() => setErrorMesage(null)}
    >
      <Alert message={errorMessage} type="error" />
      <Formik
        initialValues={initialValues}
        validationSchema={signUpSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const signUpData = {
            email: values.email,
            password: values.password,
            first_name: values.firstName,
            last_name: values.lastName,
            address: values.address,
            city: values.city,
            mobile_number: values.phone,
          };
          try {
            setErrorMesage(null);
            const FPVisitorID = await fetchFingerPrintVisitorId();
            const res = await UserAPI.signUp(signUpData, FPVisitorID);
            const authHeader = res.headers;
            await AuthService.setAuthSession({
              uid: authHeader.uid,
              client: authHeader.client,
              accessToken: authHeader['access-token'],
            });
            authService.login(res.data.content.user);
            setSubmitting(false);
            setOpen(false);

            // https://stackoverflow.com/a/51332885/1460358
            // React router doesn't have a funcitonality to forcefully refresh
            // Hacky forcefully refresh it is!
            history.push('/');
            history.goBack();
          } catch (error) {
            if (!error.response.data.status) {
              setErrorMesage(error.response.data.message);
            } else {
              console.error(error);
            }
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <FormikForm noValidate>
            <Form.Item>
              <Field
                type="text"
                name="firstName"
                label="First Name*"
                disabled={isSubmitting}
                error={errors.firstName}
                touched={touched.firstName}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="text"
                name="lastName"
                label="Last Name*"
                disabled={isSubmitting}
                error={errors.lastName}
                touched={touched.lastName}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="tel"
                name="phone"
                label="Mobile Number*"
                disabled={isSubmitting}
                error={errors.phone}
                touched={touched.phone}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="email"
                name="email"
                label="Email*"
                disabled={isSubmitting}
                error={errors.email}
                touched={touched.email}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="text"
                name="address"
                label="Address*"
                disabled={isSubmitting}
                error={errors.address}
                touched={touched.address}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="text"
                name="city"
                label="City*"
                disabled={isSubmitting}
                error={errors.city}
                touched={touched.city}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="password"
                name="password"
                label="Password*"
                disabled={isSubmitting}
                error={errors.password}
                touched={touched.password}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                disabled={isSubmitting}
                error={errors.confirmPassword}
                touched={touched.confirmPassword}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className="action-btn"
                loading={isSubmitting}
                fullWidth
              >
                SIGN UP
              </Button>
            </Form.Item>
          </FormikForm>
        )}
      </Formik>
      <div className="footer-links">
        By signing up, you agree to our &nbsp;
        <Button textOnly underline>
          Terms of Use
        </Button>
      </div>
    </Modal>
  );
};

export default SignUp;
