import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

import * as Form from 'components/common/form';
import { Heading } from 'components/common/typography';

import { PurchaseAPI } from 'api';

import RoutesGenerator, { Route } from 'services/router/generator';

import BankDetails from '../bank-details';

import './styles.less';

const { Button } = Form;

interface CompletePurchaseProps {
  bankDetails: any;
  options: any;
}

const CompletePurchase = (props: CompletePurchaseProps) => {
  const history = useHistory();
  const { bankDetails, options } = props;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClick = async () => {
    try {
      setIsSubmitting(true);
      await PurchaseAPI.purchaseItem({
        ...options,
        refNumber: bankDetails.bank_details.reference_number,
      });
      setIsSubmitting(false);
      history.push(
        RoutesGenerator(Route.Dashboard),
      );
    } catch (error) {
      setIsSubmitting(true);
      console.error(error);
    }
  };

  return (
    <div className="complete-purchase">
      <Row gutter={24} type="flex">
        <Col md={24}>
          <Heading type={3}>Please follow the below instructions carefully!</Heading>
          <p>Step 1: Fill the cash deposit slip using the below details.</p>
          <BankDetails bankDetails={bankDetails.bank_details} />
          <p>Step 2: Once you complete the bank transfer, please submit the transaction details on "My Orders" under the "Dashboard" section.</p>
        </Col>
        <Col md={24}>
          <Button size="large" className="next-btn" onClick={handleClick} loading={isSubmitting}>
            COMPLETE PURCHASE
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default CompletePurchase;
