import React, { useEffect } from 'react';
// @ts-ignore: Unreachable code error
import { useCountUp } from 'react-countup';

import Icon from 'components/common/icon';

import './styles.less';

interface ItemProps {
  icon: any;
  label: string;
  count: number;
  hasSeen: boolean;
}

const Item = (props: ItemProps) => {
  const {
    icon,
    label,
    count,
    hasSeen,
  } = props;
  const { countUp, start } = useCountUp({
    end: count,
    delay: 1000,
    duration: 3,
  });

  useEffect(() => {
    if (hasSeen) {
      start();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSeen]);

  return (
    <div className="statistic-item">
      <div className="icon-container">
        <Icon name={icon} className="icon" />
      </div>
      <div className="content">
        <h3>{countUp}</h3>
        <h4>{label}</h4>
      </div>
    </div>
  );
};

export default Item;
