import React from 'react';

import './styles.less';

interface DurationProps {
  float?: boolean;
}

const Duration = ({ float, children }: React.PropsWithChildren<DurationProps>) => (
  <div className={`duration ${float ? 'float' : ''}`}>{children}</div>
);

export default Duration;
