import React from 'react';

import './styles.less';

const Item = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  return (
    <div className="form-item">
      {children}
    </div>
  );
};

export default Item;
