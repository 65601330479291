import React from 'react';

import './heading.less';

interface HeadingProps {
  partialUnderline?: boolean;
  center?: boolean;
  inverted?: boolean;
  children: string;
  type?: number;
}

const Heading = (props: HeadingProps) => {
  const {
    partialUnderline,
    center,
    inverted,
    type = 2,
    children,
  } = props;

  const styles = [];

  if (partialUnderline) {
    styles.push('partial-underline');
  }

  if (center) {
    styles.push('center');
  }

  if (inverted) {
    styles.push('inverted');
  }

  const HeadingType = (props: any) => {
    const { children } = props;

    switch (type) {
      case 3:
        return <h3>{children}</h3>;
      case 4:
        return <h4>{children}</h4>;
      case 5:
        return <h5>{children}</h5>;
      default:
        return <h2>{children}</h2>;
    }
  };

  return (
    <div className={`heading ${styles.join(' ')}`}>
      <HeadingType>
        {children}
      </HeadingType>
    </div>
  );
};

export default Heading;
