import React, { useRef } from 'react';
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';

import { Heading } from 'components/common/typography';
import {
  Subject,
  SubjectMoreDetails,
} from 'components/common/course';
import Icon from 'components/common/icon';
import { Button } from 'components/common/form';

import RoutesGenerator, { Route } from 'services/router/generator';

import './styles.less';

const settings = {
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesPerRow: 3,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesPerRow: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesPerRow: 2,
      },
    },
  ],
};

const RelatedSubject = (props: any) => {
  const sliderRef = useRef<any>(null);
  const history = useHistory();

  const slides = props.subjects.map((subject: any) => (
    <div className="related-subjects__slide-item" key={subject.id}>
      <Subject
        subject={subject}
        onClick={
          () => history.push(RoutesGenerator(Route.Subject, { courseId: subject.course_details.id, subjectId: subject.id }))
        }
        moreDetails={(
          <SubjectMoreDetails
            courseName={subject.course_details.course_title}
            stageName={subject.course_details.stage_title}
            duration={subject.lecture_hours}
          />
        )}
      />
    </div>
  ));
  return (
    <section className="section-container related-subjects__section">
      <div className="container">
        <Heading center partialUnderline>
          Related Subjects
        </Heading>
        <div className="related-subjects__slider-controls">
          <Button textOnly onClick={() => sliderRef.current.slickPrev()}>
            <Icon name="left-small" />
          </Button>
          <Button textOnly onClick={() => sliderRef.current.slickNext()}>
            <Icon name="right-small" />
          </Button>
        </div>
        <Slider {...settings} ref={sliderRef}>{slides}</Slider>
      </div>
    </section>
  );
};

export default RelatedSubject;
