import React, { useEffect, useState } from 'react';

import Modal from 'components/common/modal';
import './styles.less';
import { UserAPI } from 'api';
import { Button } from 'antd';

interface VideoModalProps {
  open: boolean;
  url: string;
  setOpen: (state: boolean) => void;
}

const VideoModal = (props: VideoModalProps) => {
  const { open, setOpen, url } = props;
  const [ isFullScreen , setIsFullScreen] = useState(false);
  const [userData, setUserData] = useState({
      id: "",
      mobile_number: "",
  });

  const getUserProfileData = async () => {
    const res = await UserAPI.getMemberProfile();
    setUserData(res.content.user);
  }

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      let element = document.getElementById('vid-cont')
      if(element){
        element.requestFullscreen();
      }
    } else if (document.exitFullscreen) {
        document.exitFullscreen();
    }
  }

  const getRandomNumber = (min:number, max:number) => {
    const randomDecimal = Math.random();
    const randomNumber = Math.floor(randomDecimal * (max - min + 1)) + min;
    return randomNumber > 100 ? 100 : randomNumber;
  }

  useEffect(() => {

    getUserProfileData();

    function onFullscreenChange() {
      setIsFullScreen(Boolean(document.fullscreenElement));
    }
    document.addEventListener('fullscreenchange', onFullscreenChange);

  }, [])

  useEffect( ()=>{
    let watermarkTimer:any
    let hideTimer:any
    let initialHideTimer:any

    let getTimeout = setTimeout(()=>{
      const videoContainer = document.querySelector('.video')
      const watermarkContainer = document.querySelector('.watermark-container')
      const watermark = document.createElement("i")
      const currentWatermarks = document.querySelectorAll('.video i')

      if(currentWatermarks){
        currentWatermarks.forEach( (el)=> { el.remove()} )
      }

      watermark.innerHTML = `© Copyright | Lurn <br> User: ${userData.mobile_number}`
      watermark.classList.add('watermark')
      watermark.style.top = `${getRandomNumber(1,100)}px`
      watermark.style.left = `${getRandomNumber(1,100)}px`

      if(videoContainer){
        videoContainer.setAttribute("style", "position: relative;")
        videoContainer.appendChild(watermark)
      }

      initialHideTimer = setTimeout( ()=>{
        watermark.classList.add('invisible')
      }, 2000 )

      watermarkTimer = setInterval( ()=>{
        if(watermark.classList.contains('invisible')){
          watermark.classList.remove('invisible')
        }

        hideTimer = setTimeout( ()=>{
          watermark.classList.add('invisible')
        }, 2000 )

        if(!watermark.classList.contains('invisible')){
          watermark.style.top = `${getRandomNumber(1,80)}%`
          watermark.style.left = `${getRandomNumber(1,80)}%`
        }
      }, 20000 )
    }, 100)

    return ()=> {
      clearInterval(watermarkTimer)
      clearTimeout(getTimeout)
      clearTimeout(hideTimer)
      clearTimeout(initialHideTimer)
    }
  } );


  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size="normal"
      destroyOnClose
    >
      {/* <div className="video-container">
        <div className='video' dangerouslySetInnerHTML={{ __html: url }} />
        <div className="watermark">
          {userData.first_name} <br />
          {userData.last_name} <br />
          {userData.mobile_number} <br />
        </div>
      </div> */}

    <div id="vid-cont" className="video-container">
        <div  className='video' dangerouslySetInnerHTML={{ __html: url }} />
        {
          isFullScreen == false &&
          <Button className="fullscreen-button" onClick={toggleFullscreen}>Toggle Fullscreen</Button>
        }
    </div>

    </Modal>
  );
};

export default VideoModal;
