import React from 'react';
import { Input as ANTInput } from 'antd';
import { InputProps as ANTInputProps } from 'antd/lib/input';

import FieldWrapper from './field-wrapper';

import './styles.less';

interface InputProps extends ANTInputProps {
  label?: string;
  field?: object;
  error?: string | undefined;
  touched?: boolean | undefined;
}

const Input = (props: InputProps) => {
  const {
    type,
    name,
    placeholder,
    value,
    onChange,
    onBlur,
    error,
    touched,
    label,
    disabled = false,
  } = props;

  let { className = '' } = props;

  if (error && touched) {
    className += ' has-error';
  }

  return (
    <FieldWrapper name={name} label={label} touched={touched} error={error}>
      <ANTInput
        id={name}
        type={type}
        name={name}
        className={`input ${className}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
    </FieldWrapper>
  );
};

export default Input;
