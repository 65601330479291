import React, { useState } from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import Alert from 'components/common/alert';
import Modal from 'components/common/modal';
import * as Form from 'components/common/form';

import UserContainer from 'containers/auth';

import { UserAPI } from 'api';

import RoutesRegistry from 'services/router/registry';
import * as AuthService from 'services/auth';
import { fetchFingerPrintVisitorId } from 'services/fingerprint';

import { AuthModal } from './enums';

import './styles.less';

const { Input, Button } = Form;

interface SignInProps {
  open: boolean;
  setOpen: Function;
}

const SignInFooter = (props: any) => {
  const { setOpen } = props;

  return (
    <div className="footer-links footer-links-space-between">
      <Button
        onClick={() => setOpen(AuthModal.ForgotPassword)}
        textOnly
        underline
      >
        Forgot Password
      </Button>
      <div>
        Don't have an account? &nbsp;
        <Button textOnly underline onClick={() => setOpen(AuthModal.SignUp)}>
          Sign Up
        </Button>
      </div>
    </div>
  );
};

const signInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address.')
    .required('Email cannot be blank.'),
  password: Yup.string().required('Please enter your password.'),
});

const SignIn = (props: SignInProps) => {
  const authService = UserContainer.useContainer();
  const { open, setOpen } = props;
  const initialValues = { email: '', password: '' };
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Sign In to your lurn.lk Account"
      customFooter={<SignInFooter setOpen={setOpen} />}
      destroyOnClose
      afterClose={() => setErrorMessage(null)}
    >
      <Alert message={errorMessage} type="error" />
      <Formik
        initialValues={initialValues}
        validationSchema={signInSchema}
        onSubmit={async ({ email, password }, { setSubmitting }) => {
          try {
            setErrorMessage(null);
            const FPVisitorID = await fetchFingerPrintVisitorId();
            const res = await UserAPI.signIn({ email, password }, FPVisitorID);
            const authHeader = res.headers;
            await AuthService.setAuthSession({
              uid: authHeader.uid,
              client: authHeader.client,
              accessToken: authHeader['access-token'],
            });
            authService.login(res.data.content.user);
            setSubmitting(false);
            setOpen(false);
            
            // https://stackoverflow.com/a/51332885/1460358
            // React router doesn't have a funcitonality to forcefully refresh
            // Hacky forcefully refresh it is!
            history.push('/');
            history.goBack();
          } catch (error) {
            if (!error.response.data.status) {
              setErrorMessage(error.response.data.message);
            } else {
              console.error(error);
            }
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <FormikForm noValidate>
            <Form.Item>
              <Field
                type="email"
                name="email"
                label="Email"
                disabled={isSubmitting}
                error={errors.email}
                touched={touched.email}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="password"
                name="password"
                label="Password"
                disabled={isSubmitting}
                error={errors.password}
                touched={touched.password}
                as={Input}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              className="action-btn"
              loading={isSubmitting}
              fullWidth
            >
              SIGN IN
            </Button>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default SignIn;
