import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Row, Col, message } from "antd";

import { Heading } from "components/common/typography";
import Price from "components/common/course/price";
import { Button } from "components/common/form";
import { Rating, Duration } from "components/common/course";
import { truncate } from "utils";

import "./index.less";

interface GoTo {
  label: string;
  goTo: (id: number) => void;
}

interface SubjectItemProps {
  subject: any;
  actionBtn: GoTo;
}

const SubjectItem = (props: SubjectItemProps) => {
  const { subject, actionBtn } = props;
  const [batchExpired, setBatchExpired] = useState(false);
  const location = useLocation()
  const { hash, pathname, search } = location

  const pricing = () => {
    if (subject.original_price) {
      if(!subject.is_purchased || (subject.sub_order_cancel && subject.is_purchased)){
        return (
          <Price
            price={subject.original_price}
            discountedPrice={subject.discounted_price}
            className="pricing"
          />
        );
      }else if(subject.is_purchased && subject.purchased_exam_badge == null){
        return (
          <Price
            price={subject.original_price}
            discountedPrice={subject.discounted_price}
            className="pricing"
          />
        );
      }

    }
    return null;
  };

  const checkBatchIsExpired = () => {
    let difference_In_Time = 0;

    //check dashboard page
    if (subject.exam_badge != null) {
      if (subject.exam_badge.end_date) {
        const today = new Date(Date.now());
        // if (endDateList.[])
        const expDate = new Date(subject.exam_badge.end_date + "T00:00");
        const dayMilisecons = 1000 * 60 * 60 * 24;
        //set purchased date time / difference
        difference_In_Time =
          expDate.getTime() - today.getTime() - dayMilisecons;
        if (difference_In_Time > 0) {
          setBatchExpired(false);
        } else {
          setBatchExpired(true);
        }
      } else {
        setBatchExpired(true);
      }
    } else {
      if (typeof subject.exam_badge === "undefined") {
        setBatchExpired(false);
      } else {
        setBatchExpired(true);
      }
    }
  };

  const hours = `${subject.lecture_hours} Hours`;

  const popupFlashMessage = () => {
    message.warn(
      // "Batch Expired! You need to complete the payments to access the syllabus."
      "The batch has expired! To gain access to the syllabus, you have to purchase the subject using a new batch"
    );
  };

  const navigateToViewSubjectPage = () => {
    //check batch is expired(Dashboard Page)
    if (batchExpired) {
      popupFlashMessage();
    } else {
      actionBtn.goTo(subject.id);
    }
  };

  const getExamBatch = () => {
    if (subject.exam_badge != undefined && !batchExpired){
      return `${subject.exam_badge.name}`
    }
    else if (subject.exam_badge != undefined && batchExpired){
      return `${subject.exam_badge.name} (Expired)`
    }
    else{
      return ''
    }
  }

  useEffect(() => {
    checkBatchIsExpired();
  }, []);

  return (
    <div
      className="subject-item"
      onClick={navigateToViewSubjectPage}
    >
      <Row>
        <Col sm={24} xl={6} className="img-container">
          <img src={subject.image ? subject.image.url : null} alt="Subject" />
          <Duration float>{hours}</Duration>
        </Col>

        <Col sm={24} xl={12} className="content">
          <Heading type={3}>{truncate(subject.title, 80)}</Heading>
          <Rating
            rating={subject.rating}
            count={subject.rating_count}
            className="rating"
          />
          <div>
            {subject.subject_summary
              ? truncate(subject.subject_summary, 170)
              : null}
            {(subject.free_trial == "valid" && subject.remaining_hours != null) && <p className="free-trial-hours">Remaining Free Trial Hours: {subject.remaining_hours}</p>}
            {subject.free_trial == "expired" && <p className="free-trial-hours">Free Trial Expired</p>}
          </div>
          {/* <Button
            size="large"
            className="mobile"
            onClick={() => actionBtn.goTo(subject.id)}>
            {actionBtn.label}
          </Button> */}
        </Col>

        <Col sm={24} xl={6}>
          <div className="content side">
            { pathname == "/dashboard" && <Heading type={4}>{`${getExamBatch()}, ${subject.purchased_language == 'en' ? 'English' : 'Sinhala'}`}</Heading> }
            <div className="price-wrapper">{pricing()}</div>
            <div className="course-hours">
              <Duration>{hours}</Duration>
            </div>
            <Button size="large" onClick={() => navigateToViewSubjectPage()}>
              {actionBtn.label}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SubjectItem;
