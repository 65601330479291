import React from "react";
import { Divider } from "antd";

import { Heading } from "components/common/typography";
import Price from "components/common/course/price";
import Rating from "../rating";

import "./styles.less";

interface CourseProps {
  title: string;
  price: number;
  discountedPrice?: number;
  className?: string;
  courseTitle?: string;
  actionBtn?: JSX.Element;
  hasPurchased?: boolean;
  ratings?: { rating: number; count: number };
  easyPayAvailable: boolean;
  isOneSubjectPurchased: boolean;
}

const CourseHeader = (props: CourseProps) => {
  const {
    title,
    price,
    discountedPrice,
    courseTitle,
    actionBtn,
    hasPurchased = false,
    ratings,
    className = "",
    easyPayAvailable,
    isOneSubjectPurchased = false,
  } = props;

  const degreeTitle = title ? `${title}`: ``;
  const subjectTitle = courseTitle && degreeTitle ? `${courseTitle} : ${degreeTitle}` : courseTitle ? `${courseTitle} ${degreeTitle}` : degreeTitle;

  const pricing = () => {
    return <Price price={price} discountedPrice={discountedPrice} />;
  };

  return (
    <div className={`course-header ${className}`}>
      <Heading type={2} partialUnderline={!easyPayAvailable}>
        {subjectTitle}
      </Heading>
      <div className="course-subheader">
        {easyPayAvailable ? (
          <Heading type={4} partialUnderline>
            LURN Easy Pay Plan Available!
          </Heading>
        ) : null}
        {ratings ? (
          <Rating rating={ratings.rating} count={ratings.count} />
        ) : null}
      </div>

      {isOneSubjectPurchased == false && (
        <div className="course-pricing">
          {pricing()}
          {actionBtn}
        </div>
      )}

      <Divider />
    </div>
  );
};

export default CourseHeader;
