import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import { Breadcrumb as BCInterface } from './types';

import './styles.less';

interface BreadcrumbProps {
  crumbs: BCInterface[];
}

const Breadcrumbs = (props: BreadcrumbProps) => {
  const { crumbs } = props;

  const items = crumbs.map((crumb: any) => {
    if (crumb.path) {
      return (
        <Breadcrumb.Item key={crumb.title}>
          <Link to={crumb.path}>{crumb.title}</Link>
        </Breadcrumb.Item>
      );
    }
    return <Breadcrumb.Item key={crumb.title}>{crumb.title}</Breadcrumb.Item>;
  });

  return (
    <Breadcrumb separator=">" className="breadcrumbs">
      {items}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
