import RH from './request-handler';
import { Response } from './request.interface';
import { getAuthData } from 'services/auth';

const getPurchaseOptions = async (
  type: string,
  id: number,
): Promise<Response> => {
  const res = await RH.get(`/payment/options/${id}/${type}`);
  return res.data;
};

const getBankDetails = async (payload: any): Promise<Response> => {
  const queryString = payload.refNumber
    ? `option=${payload.paymentMethod}&reference_number=${payload.refNumber}`
    : `option=${payload.paymentMethod}&installment_plan=${payload.installmentPlan}&stage_or_subject=${payload.type}&id=${payload.id}&exam_badge_id=${payload.batch}&purchase_lan=${payload.language}&aff_code=${payload.affiliationCode}`;
  const res = await RH.get(`/bank/details?${queryString}`);
  return res.data;
};

const purchaseItem = async (payload: any): Promise<Response> => {
  const res = await RH.post('/bank/payment', {
    reference_number: payload.refNumber,
    installment_plan_id: payload.paymentPlan.id,
    stage_or_subject: payload.type,
    stage_or_subject_id: payload.id,
    exam_badge_id: payload.batch,
    purchase_lan: payload.language,
    aff_code: payload.affiliationCode
  });
  return res.data;
};

const purchaseFreetrial = async (payload: any): Promise<Response> => {
  const authData = getAuthData();

  const res = await RH.post('/freetrial/payment', {
    installment_plan_id: payload.paymentPlan.id,
    stage_or_subject: payload.type,
    stage_or_subject_id: payload.id,
    exam_badge_id: payload.batch,
    purchase_lan: payload.language,
    aff_code: payload.affiliationCode,
    client: authData.client,
    uid: authData.uid
  });
  return res.data;
};

const submitDepositSlip = async (payload: any): Promise<Response> => {
  const res = await RH.post('/bank/payment/submit_slip', payload);
  return res.data;
};

const validateAffiliationCode = async (
  type: string,
  id: number,
  affCode: string,
): Promise<Response> => {
  const res = await RH.get(`/payment/options/${id}/${type}?aff_code=${affCode}`);
  return res.data;
};

export default {
  getPurchaseOptions,
  getBankDetails,
  purchaseItem,
  purchaseFreetrial,
  submitDepositSlip,
  validateAffiliationCode,
};
