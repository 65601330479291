import React, { useState, useEffect } from 'react';

import { GeneralAPI } from 'api';

import FSL from 'components/common/full-screen-loader';
import Landing from './landing';
import Features from './features';
import PopularLearningPath from './learning-path';
import Statistics from './statistics';
import PopularSubjects from './popular-subjects';
import WhyUs from './why-us';

const Home = () => {
  const [busy, setBusy] = useState(true);
  const [data, setData] = useState<any>({});

  const init = async () => {
    try {
      setBusy(true);
      const res = await GeneralAPI.home();
      setData(res.content);
      setBusy(false);
    } catch (err) {
      setBusy(false);
      console.error(err);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const Comp = () => {
    if (busy) {
      return <FSL />;
    }
    return (
      <>
        <Landing content={data.home} />
        <Features content={data.features} />
        <PopularLearningPath content={data.popular_learning_paths} />
        <Statistics content={data.statistics} />
        <PopularSubjects content={data.subjects} />
        <WhyUs content={data.why_us} />
      </>
    );
  };

  return <Comp />;
};

export default Home;
