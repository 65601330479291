import React from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

import { Heading } from 'components/common/typography';
import { Category } from 'components/common/course';

import RoutesGenerator, { Route, } from 'services/router/generator';


import './styles.less';

const PopularLearningPath = (props: any) => {
  const { content } = props;
  const history = useHistory();

  const categories = content.map((course: any) => (
    <Col xs={24} sm={12} md={12} lg={6} xl={6} key={course.id}>
      <Category
        category={course}
        onClick={
          (courseId: number) => history.push(RoutesGenerator(Route.Course, { id: courseId }))
        }
      />
    </Col>
  ));

  return (
    <section className="popular-subjects section-container">
      <div className="container">
        <Heading partialUnderline center>Popular Learning Path</Heading>
        <Row gutter={[32, 32]}>
          {categories}
        </Row>
      </div>
    </section>
  );
};

export default PopularLearningPath;
