import React, { useState, useEffect } from "react";
import { Divider } from "antd";
import { Element, scroller } from "react-scroll";

import { CourseAPI } from "api";

import TitleArea from "components/common/content/title";
import { Heading } from "components/common/typography";
import { Breadcrumb } from "components/common/breadcrumb/types";
import Container from "components/common/section-container";
import FSL from "components/common/full-screen-loader";
import landingBg from "assets/images/home/home-landing-bg.jpg";
import { VideoContainer } from "components/common/course";

import RoutesRegistry from "services/router/registry";

import { truncate } from "utils";

import CourseStage from "./stage";

import "./styles.less";

const crumbs: Breadcrumb[] = [
  RoutesRegistry.Courses,
  {
    title: RoutesRegistry.Courses.Course.title,
  },
];

const IntroSection = (props: any) => {
  const { title, description, image, video } = props;

  return (
    <Container className="intro-container">
      <VideoContainer
        imageUrl={image}
        videoUrl={video.videoUrl}
        showVideo={video.showVideo}
        className="video-container"
      />
      <Heading type={3}>{title}</Heading>
      <Divider />
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Container>
  );
};

const getStageName = (id: string | null) => `stage${id}`;

const Course = (props: any) => {
  const { match, location } = props;
  const [course, setCourse] = useState<any>(null);
  const [busy, setBusy] = useState(true);
  const { id } = match.params;
  const urlParams = new URLSearchParams(location.search);
  const stageId = urlParams.get("stage");

  useEffect(() => {
    const getCourses = async () => {
      try {
        const res = await CourseAPI.course(Number(id));
        setCourse(res.content);
        setBusy(false);

        if (stageId) {
          scroller.scrollTo(getStageName(stageId), {
            offset: -50,
          });
        }
      } catch (error) {
        setBusy(false);
        console.log(error);
      }
    };

    getCourses();
  }, []);

  if (busy) {
    return <FSL />;
  }

  const stages = course.stages.map((stage: any) => (
    <Element name={getStageName(stageId)}>
      <CourseStage
        key={stage.id}
        id={course.id}
        stage={stage}
        courseTitle={course.title}
      />
    </Element>
  ));

  return (
    <>
      <TitleArea
        backgroundImg={landingBg}
        icon="charted-acc"
        breadcrumbs={crumbs}
        title={truncate(course.title, 35)}
      />
      <section className="section-container">
        <IntroSection
          title={course.title}
          description={course.description}
          image={course.image.url}
          video={{
            videoUrl: course.video_url,
            showVideo: course.show_video,
          }}
        />
        <div className="stages-container">{stages}</div>
      </section>
    </>
  );
};

export default Course;
