import React, { useEffect, useState } from 'react';

import { Heading } from 'components/common/typography';
import Spinner from 'components/common/spinner';

import { CourseAPI } from 'api';

import './styles.less';

const Lecturer = (props: any) => {
  const { lecturer } = props;

  return (
    <div className="lecturer-item">
      <img
        src={lecturer.image.url}
        alt="Lecturer"
      />
      <div className="lecturer-item__content">
        <Heading type={4}>{`${lecturer.first_name} ${lecturer.last_name}`}</Heading>
        <Heading type={5}>{lecturer.qualifications}</Heading>
        <p>
          {lecturer.description}
        </p>
      </div>
    </div>
  );
};

const Lecturers = (props: any) => {
  const { id } = props;
  const [lecturers, setLecturers] = useState([]);
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    const fetchSyllabus = async () => {
      try {
        const res = await CourseAPI.getLecturers(id);
        setLecturers(res.content.lecturers);
        setBusy(false);
      } catch (error) {
        console.error(error);
        setBusy(false);
      }
    };

    fetchSyllabus();
  }, []);

  if (busy) {
    return <Spinner />;
  }

  if (lecturers && lecturers.length > 0) {
    return (
      <>
        {lecturers.map((lecturer: any) => <Lecturer lecturer={lecturer} key={lecturer.id} />)}
      </>
    );
  }

  return (
    <div className="content-unavailable">
      There are no lecturers assigned to this subject yet.
    </div>
  );
};

export default Lecturers;
