import React, { useEffect, useState } from 'react';

import statsBg from 'assets/images/home/stats_bg.jpg';

import Item from './item';

import './styles.less';

const Statistics = (props: any) => {
  const { content } = props;
  const [seen, setSeen] = useState(false);

  useEffect(() => {
    const activateOnScroll = () => {
      const element = document.querySelector('#statistics');

      if (element) {
        const position = element.getBoundingClientRect();

        if (position.top < window.innerHeight && position.bottom >= 0) {
          setSeen(true);
          window.removeEventListener('scroll', activateOnScroll);
        }
      }
    };

    window.addEventListener('scroll', activateOnScroll);
  }, []);

  return (
    <section id="statistics" className="statistics" style={{ backgroundImage: `url(${statsBg})` }}>
      <div className="container">
        <Item icon="students" label="Students" count={content.students} hasSeen={seen} />
        <Item icon="courses" label="Courses" count={content.courses} hasSeen={seen} />
        <Item icon="lecturers" label="Lecturers" count={content.lecturers} hasSeen={seen} />
      </div>
    </section>
  );
};

export default Statistics;
