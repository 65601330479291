import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

interface ScrollToTopProps {
  history: any;
}

const ScrollToTop = (props: React.PropsWithChildren<ScrollToTopProps>) => {
  const { history, children } = props;
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return <>{children}</>;
};

export default withRouter(ScrollToTop);
