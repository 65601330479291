import React from 'react';
import { Row, Col, Divider } from 'antd';

import TitleArea from 'components/common/content/title';
import Icon from 'components/common/icon';
import { Heading } from 'components/common/typography';

import landingBg from 'assets/images/home/home-landing-bg.jpg';
import map from 'assets/images/map.jpg';

import ContactForm from './contact-form';

import './styles.less';

const Contact = () => {
  return (
    <>
      <TitleArea
        backgroundImg={landingBg}
        title="We'd love to hear from you!"
        icon="courses"
      />
      <section className="section-container contact">
        <div className="container">
          <div className="contact__container">
            <Row gutter={[24, 24]} type="flex" className="contact__row">
              <Col xs={24} lg={16}>
                <div className="contact__content">
                  <ContactForm />
                </div>
              </Col>
              <Col xs={24} lg={8}>
                <div className="contact__content">
                  <Heading type={3}>Contact Details</Heading>

                  <Divider className="divider" />

                  <div className="address">
                    <Icon size={2.5} name="address" className="contact-icon" />
                    <div>
                      <Heading type={4}>Address</Heading>
                      <p>
                      lurn.lk <br />
                      23/22, 3rd lane,<br /> Kattuwawala Mawatha,<br /> Ambillawatta Road, <br />Boralesgamuwa, <br />Sri Lanka.
                      </p>
                    </div>
                  </div>

                  <Divider className="divider" />

                  <div className="contact-info">
                    <Icon size={2.5} name="contact" className="contact-icon" />
                    <div>
                      <Heading type={4}>Phone</Heading>
                      <p>
                      <a href="tel:+94777275914">(+94) 777 275 914</a>
                      <br />
                        <a href="mailto:info@lurn.lk">info@lurn.lk</a>
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div
              className="contact__map"
              style={{ backgroundImage: `url(${map})` }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
