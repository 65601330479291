import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { message } from 'antd';

import { CourseAPI } from 'api';

import TitleArea from 'components/common/content/title';
import { Breadcrumb } from 'components/common/breadcrumb/types';
import Container from 'components/common/section-container';
import { Heading } from 'components/common/typography';
import Collapsible from 'components/common/collapsible';
import { Button } from 'components/common/form';
import Spinner from 'components/common/spinner';
import Alert from 'components/common/alert';

import RoutesRegistry from 'services/router/registry';

import landingBg from 'assets/images/home/home-landing-bg.jpg';

import Question from './question';

import './styles.less';

const MCQ = (props: any) => {
  const { match, location } = props;
  const urlParams = new URLSearchParams(location.search);
  const language = urlParams.get('language');
  const [mcqs, setMcqs] = useState<any>(null);
  const [busy, setBusy] = useState(true);
  const [answers, setAnswers] = useState<any>([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const { cid, sid, mcqid } = match.params;

  const getMCQ = async () => {
    try {
      const res = await CourseAPI.getMCQ(Number(mcqid), language);
      setMcqs(res.content);
      setBusy(false);
    } catch (error) {
      setBusy(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getMCQ();
  }, []);

  const updateAnswers = (answer: any) => {
    const existingAnswerIndex = _.findIndex(answers, { question_id: answer.question_id });
    const answersArr = _.clone(answers);

    if (existingAnswerIndex === -1) {
      answersArr.push(answer);
    } else {
      answersArr[existingAnswerIndex] = answer;
    }

    setAnswers(answersArr);
  };

  const Questions = () => {
    const { questions } = mcqs;
    const totalCount = questions.length;
    const content = questions.map((item: any, index: number) => {
      const answer = _.find(answers, { question_id: item.id });
      return {
        title: `Question ${index + 1} of ${totalCount}`,
        body: (
          <Question
            id={item.id}
            question={item.question}
            answers={item.answers}
            format={item.answer_format}
            onAnswer={updateAnswers}
            selectedAnswer={answer ? answer.answer : item.user_answer}
            hasSubmitted={hasSubmitted}
            isReview={!mcqs.is_new}
            correctAnswer={item.correct_answer}
            userAnswer={item.user_answer}
          />
        ),
        className: ((hasSubmitted && !answer) || (!mcqs.is_new && !item.is_correct)) ? 'mcq-not-answered' : '',
      };
    });

    return <Collapsible content={content} expand={mcqs.is_new ? 'all' : 0} />;
  };

  const submit = async () => {
    setHasSubmitted(true);
    setError('');

    if (answers.length < mcqs.questions.length) {
      setError('Please answer all the questions before submitting.');
    } else {
      setIsSubmitting(true);

      try {
        await CourseAPI.submitMCQ(mcqid, answers);
        message.success('You have successfully submitted the questionnaire.');
        setIsSubmitting(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        await getMCQ();
      } catch (err) {
        console.error(err);
        setIsSubmitting(false);
      }
    }
  };

  const crumbs: Breadcrumb[] = [
    RoutesRegistry.Courses,
    {
      title: RoutesRegistry.Courses.Course.title,
      path: `${RoutesRegistry.Courses.path}/${cid}`,
    },
    {
      title: RoutesRegistry.Courses.Course.Subject.title,
      path: `${RoutesRegistry.Courses.path}/${cid}/subjects/${sid}`,
    },
    {
      title: 'MCQ',
    },
  ];

  const Content = () => {
    if (busy) {
      return <Spinner />;
    }

    return (
      <>
        <Heading type={2}>
          {mcqs.mcq_title}
        </Heading>
        <Heading type={4} partialUnderline>
          {mcqs.is_new ? 'Multiple Choice Questions' : `MCQ Answers Sheet: You have been scored ${mcqs.out_of_text}`}
        </Heading>
        <Questions />
        {!mcqs.is_new || (
          <div className="mcq__footer">
            <Alert type="error" message={error} />
            <Button onClick={submit} loading={isSubmitting}>SUBMIT</Button>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <TitleArea backgroundImg={landingBg} breadcrumbs={crumbs} />
      <Container className="mcq__container" startFromTop>
        <Content />
      </Container>
    </>
  );
};

export default MCQ;
