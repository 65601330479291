import React from 'react';
import { Select } from 'antd';

import './styles.less';

const { Option } = Select;

interface SelectOption {
  label: string;
  value: any;
  disabled?: boolean;
}

interface SelectProps {
  options: SelectOption[];
  placeholder?: string;
  defaultValue?: any;
  disabled?: boolean;
  onChange: (value: any) => void;
}

const CustomSelect = (props: SelectProps) => {
  const { placeholder, options, defaultValue, disabled, onChange } = props;

  return (
    <Select
      placeholder={placeholder}
      className="custom-select"
      onChange={onChange}
      defaultValue={defaultValue}
      disabled={disabled}
    >
      {options.map((option) => (
        <Option
          key={option.value}
          value={option.value}
          disabled={option.disabled}
        >
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default CustomSelect;
