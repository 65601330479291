import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

import enIcon from 'assets/images/en.svg';
import lkIcon from 'assets/images/lk.svg';

import './index.less';

const { Option } = Select;

export const Language = Object.freeze({
  EN: 'en',
  LK: 'lk',
});

export const defaultLang = 'defaultLang';

const setDefaultLanguage = (value: any) => {
  localStorage.setItem(defaultLang, String(value));
  window.location.reload(true);
};

const LangSelector = () => {
  const [lang, setLang] = useState<any>(Language.EN);
  const [busy, setBusy] = useState<boolean>(true);

  useEffect(() => {
    const selectedLang = localStorage.getItem(defaultLang);
    if (selectedLang) {
      setLang(selectedLang);
    }
    setBusy(false);
  }, []);

  if (busy) {
    return null;
  }

  return (
    <Select defaultValue={lang} className="lang-selector" onChange={setDefaultLanguage}>
      <Option value={Language.EN}>
        <img src={enIcon} alt="Logo" className="lang-selector__icon" />
        English
      </Option>
      <Option value={Language.LK}>
        <img src={lkIcon} alt="Logo" className="lang-selector__icon" />
        Sinhala
      </Option>
    </Select>
  );
};

export default LangSelector;
