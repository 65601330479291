import React from 'react';

import './styles.less';

interface FullScreenLoaderProps {
  busy?: boolean;
}

const FullScreenLoader = (
  props: React.PropsWithChildren<FullScreenLoaderProps>,
) => {
  const { busy, children } = props;

  if (children && !busy) {
    return <>{children}</>;
  }

  return (
    <div className="full-screen-loader">
      <div className="loader">Loading...</div>
    </div>
  );
};

export default FullScreenLoader;
