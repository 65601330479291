import React from 'react';
import { useHistory } from 'react-router-dom';

import RoutesGenrator, { Route } from 'services/router/generator';

import Icon from 'components/common/icon';

import logo from 'assets/images/logo.svg';

import './styles.less';

const Footer = () => {
  const year = new Date().getFullYear();
  const history = useHistory();

  const goTo = (route: Route) => {
    history.push(RoutesGenrator(route));
  };

  return (
    <>
      <footer className="section-container">
        <div className="container">
          <div className="row">
            <div className="col">
              <img src={logo} alt="" />
              <ul className="social-links">
                <li>
                  <a href="https://facebook.com/" target="_blank" rel="noopener noreferrer" className="icon-container">
                    <Icon size={2.25} name="facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" className="icon-container">
                    <Icon size={2.25} name="twitter" />
                  </a>
                </li>
              </ul>
            </div>

            <div className="col">
              <div className="icon-container">
                <Icon size={2.25} name="address" />
              </div>
              <p>
                lurn.lk <br />
                23/22, 3rd lane,<br /> Kattuwawala Mawatha,<br /> Ambillawatta Road, <br />Boralesgamuwa, <br />Sri Lanka.
              </p>
            </div>

            <div className="col">
              <div className="icon-container">
                <Icon size={2.25} name="contact" />
              </div>
              <p>
                <a href="tel:+94777275914">(+94) 777 275 914</a>
                <br />
                <a href="mailto:info@lurn.lk">info@lurn.lk</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright-section">
        <div className="container">
          <p>© lurn.lk {year}, All Rights Reserved. <span onClick={() => goTo(Route.PrivacyPolicy)}>Privacy Policy</span> | <span onClick={() => goTo(Route.TermsAndConditions)}>Terms and Conditions</span></p>
        </div>
      </div>
    </>
  );
};

export default Footer;
