import React from 'react';
import { Tabs as ANTTabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs/index';

import './styles.less';

export const { TabPane } = ANTTabs;

export const Tabs = (props: React.PropsWithChildren<TabsProps>) => {
  const { className, children, ...rest } = props;
  const classNames = `tabs ${className}`;

  return (
    <ANTTabs className={classNames} {...rest}>
      {children}
    </ANTTabs>
  );
};
