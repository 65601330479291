import React, { useState } from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';

import Modal from 'components/common/modal';
import * as Form from 'components/common/form';
import Alert from 'components/common/alert';

import { UserAPI } from 'api';

import './styles.less';

const { Input, Button } = Form;

interface ForgotPasswordProps {
  open: boolean;
  setOpen: Function;
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address.')
    .required('Please enter your email address.'),
});

const ForgotPassword = (props: ForgotPasswordProps) => {
  const { open, setOpen } = props;
  const initialValues = { email: '' };
  const [message, setMessage] = useState<string | null>(null);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Forgot Password"
      destroyOnClose
      afterClose={() => setMessage(null)}
    >
      <Alert message={message} />
      <Formik
        initialValues={initialValues}
        validationSchema={forgotPasswordSchema}
        onSubmit={async ({ email }, { setSubmitting }) => {
          const checkInboxMessage = `Please go to your ${email} email and follow our instructions to reset your password.`;
          try {
            setMessage(null);
            await UserAPI.forgotPassword(email);
            setSubmitting(false);
            setMessage(checkInboxMessage);
          } catch (error) {
            setMessage(checkInboxMessage);
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <FormikForm noValidate>
            <Form.Item>
              <Field
                type="email"
                name="email"
                label="Email"
                disabled={isSubmitting}
                error={errors.email}
                touched={touched.email}
                as={Input}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              className="action-btn"
              loading={isSubmitting}
              fullWidth
            >
              RESET PASSWORD
            </Button>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default ForgotPassword;
