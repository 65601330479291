import { getAuthData } from 'services/auth';
import RoutesGenerator, { Route } from 'services/router/generator';

interface Params {
  type: string;
  id: number;
}

export default (params: Params, options: any) => {
  const optionsString = Object.keys(options).map(
    (key) => `${key}=${options[key]}`,
  ).join('&');
  const authData = getAuthData();
  const authString = `client=${authData.client}&uid=${authData.uid}`;

  return `
    ${process.env.REACT_APP_API}/card/payment?return_url=${encodeURIComponent(process.env.REACT_APP_HOST + RoutesGenerator(Route.PaymentReturn, params))}&cancel_url=${encodeURIComponent(process.env.REACT_APP_HOST + RoutesGenerator(Route.PaymentCancel))}&${optionsString}&${authString}
  `;
};
