import React, { useState } from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import { message } from 'antd';

import Alert from 'components/common/alert';
import * as Form from 'components/common/form';

import { UserAPI } from 'api';

const { Input, Button } = Form;

const infoFormSchema = Yup.object().shape({
  password: Yup.string().required('Please enter your password.'),
  confirmPassword: Yup.string()
    .required('Please enter the password again.')
    .test('passwords-match', 'Passwords does not match.', function (value) {
      return this.parent.password === value;
    }),
});

export default () => {
  const initialValues = { password: '', confirmPassword: '' };
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <>
      <Alert message={errorMessage} type="error" />
      <Formik
        initialValues={initialValues}
        validationSchema={infoFormSchema}
        onSubmit={async ({ password, confirmPassword }, { setSubmitting }) => {
          try {
            setErrorMessage(null);
            const userData = {
              password,
              password_confirmation: confirmPassword,
            };
            await UserAPI.updateMemberProfile(userData);
            setSubmitting(false);
            message.success('Password updated.');
          } catch (error) {
            setErrorMessage('Something went wrong. Please try again.');
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <FormikForm noValidate>
            <Form.Item>
              <Field
                type="password"
                name="password"
                label="Password"
                disabled={isSubmitting}
                error={errors.password}
                touched={touched.password}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="password"
                name="confirmPassword"
                label="Confirm password"
                disabled={isSubmitting}
                error={errors.confirmPassword}
                touched={touched.confirmPassword}
                as={Input}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              className="action-btn"
              loading={isSubmitting}
            >
              SAVE
            </Button>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};
