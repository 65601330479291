import React from 'react';
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from 'react-share';

const SocialLinks = () => {
  const url = window.location.href;
  const size = 40;

  return (
    <div className="social-links">
      <FacebookShareButton url={url}>
        {/*
        // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
        <FacebookIcon size={size} />
      </FacebookShareButton>
      <TwitterShareButton url={url}>
        {/*
        // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
        <TwitterIcon size={size} />
      </TwitterShareButton>
    </div>
  );
};

export default SocialLinks;
