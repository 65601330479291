import React from 'react';
import { Pagination } from 'antd';
import TimeAgo from 'react-timeago';

import { Heading } from 'components/common/typography';
import { Rating } from 'components/common/course';
import Spinner from 'components/common/spinner';
import Icon from 'components/common/icon';

import './styles.less';

interface ReviewsProps {
  reviews: any;
  fetchReviews: (page: number) => void;
  totalItems: number;
  currentPage: number;
  isFetchingReviews: boolean;
  pageSize: number;
}

const Review = (props: any) => {
  const { review } = props;
  const { rating, message, user, created_at } = review;
  const reviewerName = `by ${user.first_name} ${user.last_name}`;
  return (
    <div className="review-item">
      <div className="review-item__content">
        <Heading type={4}>{reviewerName}</Heading>
        <Rating rating={rating} showRatingValue={false} />
        <p>
          {message}
        </p>
        <TimeAgo date={created_at} className="review-item__time" live={false} />
      </div>
    </div>
  );
};

const ReviewLoader = ({ isLoading }: { isLoading: boolean }) => {
  if (isLoading) {
    return (
      <div className="review-item__loader">
        <Spinner />
      </div>
    );
  }
  return null;
};

const customizePagination = (page: number, type: string) => {
  switch (type) {
    case 'next':
      return <Icon name="right-small" size={1.5} />;
    case 'prev':
      return <Icon name="left-small" size={1.5} />;
    default:
      return <div>{page}</div>;
  }
};

const Reviews = (props: ReviewsProps) => {
  const {
    reviews, totalItems, currentPage, pageSize, isFetchingReviews, fetchReviews,
  } = props;
  if (reviews && reviews.length > 0) {
    return (
      <>
        <div className="review-item__container">
          <ReviewLoader isLoading={isFetchingReviews} />
          {reviews.map((review: any) => <Review key={review.id} review={review} />)}
        </div>
        <div className="review-pagination__container">
          <Pagination
            current={currentPage}
            total={totalItems}
            defaultPageSize={pageSize}
            className="review-pagination"
            onChange={fetchReviews}
            itemRender={customizePagination}
          />
        </div>
      </>
    );
  }
  return <div className="no-reviews">There are no reviews for this subject yet!</div>;
};

export default Reviews;
