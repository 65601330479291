import React, { useState } from "react";

import VideoModal from "components/common/video-modal";
import Icon from "components/common/icon";
import { Button } from "components/common/form";

import "./styles.less";

interface VideoContainer {
  imageUrl: string;
  showVideo: boolean;
  videoUrl: string;
  className?: string;
}

const VideoContainer = (props: VideoContainer) => {
  const [openVideo, setOpenVideo] = useState(false);
  const { imageUrl, showVideo, videoUrl } = props;

  return (
    <div
      className="video-container"
      style={{
        backgroundImage: `url(${imageUrl}`,
        width: "100%",
        // minHeight: "250px",
      }}>
      {showVideo ? (
        <>
          <Button onClick={() => setOpenVideo(true)} textOnly>
            <Icon
              name="play-circled"
              size={5}
              className="video-container__icon"
            />
          </Button>
          <VideoModal open={openVideo} setOpen={setOpenVideo} url={videoUrl} />
        </>
      ) : null}
    </div>
  );
};

export default VideoContainer;
