import React, { useState, useEffect } from 'react';
import { Result } from 'antd';
import { useHistory } from 'react-router-dom';

import TitleArea from 'components/common/content/title';
import Container from 'components/common/section-container';
import Wrapper from 'components/common/wrappers/inner-wrapper';
import FSL from 'components/common/full-screen-loader';
import { Button } from 'components/common/form';

import RoutesGenerator, { Route } from 'services/router/generator';

import landingBg from 'assets/images/home/home-landing-bg.jpg';

import { CourseAPI } from 'api';

import './styles.less';

const Course = (props: any) => {
  const history = useHistory();
  const { location } = props;
  const { match } = props;
  const { type, id } = match.params;
  const query = new URLSearchParams(location.search);
  const [courseInfo, setCourseInfo] = useState<any>(null);
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    const getCourseInfo = async () => {
      try {
        const res = await CourseAPI.getCourseInfo(type, id);
        setCourseInfo(res.content.details);
        setBusy(false);
      } catch (error) {
        setBusy(false);
        console.log(error);
      }
    };

    getCourseInfo();
  }, []);

  const goToDashboard = () => {
    history.push(RoutesGenerator(Route.Dashboard));
  };

  if (busy) {
    return <FSL />;
  }

  const title = `
    ${courseInfo.course_title}${courseInfo.stage_title ? (': ' + courseInfo.stage_title) : ''}${courseInfo.subject_title ? (': ' + courseInfo.subject_title) : ''}
  `;

  return (
    <>
      <TitleArea
        backgroundImg={landingBg}
        title="ONLINE PAYMENT"
        icon="charted-acc"
      />
      <section className="section-container">
        <Container>
          <Wrapper>
            <Result
              status="success"
              title={`Successfully Purchased ${title}`}
              subTitle={`Order ID: ${query.get('order_id')} Your payment will get approved in a short while.`}
              extra={[
                <Button key="console" onClick={goToDashboard}>
                  Go To Dashboard
                </Button>,
              ]}
            />
          </Wrapper>
        </Container>
      </section>
    </>
  );
};

export default Course;
