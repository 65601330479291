import React, { useState } from "react";
import { message } from "antd";

import Icon from "components/common/icon";
import VideoModal from "components/common/video-modal";

import "./index.less"

interface ResourceItemProps {
  type: string;
  name: string;
  link?: string;
  isUnlocked: boolean;
  isExpired: boolean;
  isPreviewable: boolean;
}

const ResourceIcon = (props: any) => {
  const { type } = props;

  switch (type) {
    case "JPG":
      return <Icon name="file-jpeg" />;
    case "PNG":
      return <Icon name="file-jpeg" />;
    case "JPEG":
      return <Icon name="file-jpeg" />;
    case "PDF":
      return <Icon name="file-pdf" />;
    case "DOC":
      return <Icon name="file-word" />;
    case "DOCX":
      return <Icon name="file-word" />;
    case "PPT":
      return <Icon name="file-power-point" />;
    case "TXT":
      return <Icon name="file-power-word" />;
    case "VIDEO_LINK":
      return <Icon name="file-video" />;
    default:
      return <Icon name="file-unknown" />;
  }
};

const disabledAlert = () =>
  message.warn("Please pay to access lessons.");

const expiredAlert = () =>
  message.warn(
    // "Batch Expired! You need to complete the payments to access the syllabus."
    "The batch has expired! To gain access to the syllabus, you have to purchase the subject using a new batch"
  );

const ResourceItem = (props: ResourceItemProps) => {
  const { type, name, link, isUnlocked, isExpired, isPreviewable } = props;
  const [open, setOpen] = useState(false);

  
  if (!isUnlocked) {
    return (
      <div className="resource-item disabled" onClick={() => disabledAlert()}>
        <ResourceIcon type={type} />
        <span>{name}</span>
      </div>
    );
  } else {
    if (isExpired) {
      return (
        <div className="resource-item disabled" onClick={() => expiredAlert()}>
          <ResourceIcon type={type} />
          <span>{name}</span>
        </div>
      );
    }
  }

  if (type === "VIDEO_LINK") {
    return (
      <>
        <div
          className="resource-item"
          onClick={() => {
            link && setOpen(true);
          }}>
          <ResourceIcon type={type} />
          <span>{name}</span>
          {isPreviewable && <span className="preview-tag">Preview</span>}
        </div>
        {link && <VideoModal open={open} setOpen={setOpen} url={link} />}
      </>
    );
  }

  return (
    <a
      target="_blank"
      href={link}
      rel="noopener noreferrer"
      className="resource-item">
      <ResourceIcon type={type} />
      <span>{name}</span>
      {isPreviewable && <span className="preview-tag">Preview</span>}
    </a>
  );
};

export default ResourceItem;
