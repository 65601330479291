import RH from './request-handler';
import { Response } from './request.interface';

interface ContactUs {
  name: string;
  email: string;
  subject: string;
  phone: string;
  message: string;
}

const home = async () : Promise<Response> => {
  const res = await RH.get('/landing');
  return res.data;
};

const about = async () : Promise<Response> => {
  const res = await RH.get('/about');
  return res.data;
};

const contact = async (data: ContactUs) : Promise<Response> => {
  const res = await RH.post('/contact_us', data);
  return res.data;
};

const getPrivacyPolicy = async () : Promise<Response> => {
  const res = await RH.get('/privacy_policy');
  return res.data;
};

const getTermsAndConditions = async () : Promise<Response> => {
  const res = await RH.get('/terms_and_conditions');
  return res.data;
};

const whatsappGroup = async () : Promise<Response> => {
  const res = await RH.get('/whatsapp_group');
  return res.data;
};

export default {
  home,
  about,
  contact,
  getPrivacyPolicy,
  getTermsAndConditions,
  whatsappGroup,
};
