import React from 'react';
import _ from 'lodash';

import Icon from 'components/common/icon';

import './styles.less';

interface RatingProps {
  rating: number;
  showRatingValue?: boolean;
  count?: number;
}

const Rate = (props: any) => {
  const { rate } = props;

  const starMap = _.times(5, (count: number) => count <= rate - 1);

  const stars = starMap.map((star, index) => {
    if (star) {
      return <Icon name="star" className="stars filled-star" key={index} />;
    }
    return <Icon name="star" className="stars unfilled-star" key={index} />;
  });

  return <>{stars}</>;
};

const Rating = (props: RatingProps & React.HTMLAttributes<HTMLDivElement>) => {
  const { rating, count, showRatingValue = true } = props;

  const Counter = () => {
    if (count) {
      return <>({count})</>;
    }
    return null;
  };

  const RatingValue = () => {
    if (showRatingValue) {
      return <>{rating}</>;
    }
    return null;
  };

  return (
    <div className="rating">
      <Rate rate={rating} />
      <span className="ratings-count">
        <RatingValue /> <Counter />
      </span>
    </div>
  );
};

export default Rating;
