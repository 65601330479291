import React from 'react';
import { Input as ANTInput } from 'antd';
import { TextAreaProps as ANTTextAreaProps } from 'antd/lib/input';

import FieldWrapper from './field-wrapper';

import './styles.less';

const ANTextArea = ANTInput.TextArea;

interface TextAreaProps extends ANTTextAreaProps {
  label?: string;
  field?: object;
  error?: string | undefined;
  touched?: boolean | undefined;
}

const TextArea = (props: TextAreaProps) => {
  const {
    name,
    placeholder,
    value,
    onChange,
    onBlur,
    error,
    touched,
    label,
    disabled = false,
  } = props;

  let { className = '' } = props;

  if (error && touched) {
    className += ' has-error';
  }

  return (
    <FieldWrapper name={name} label={label} touched={touched} error={error}>
      <ANTextArea
        id={name}
        name={name}
        className={`input ${className}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
    </FieldWrapper>
  );
};

export default TextArea;
