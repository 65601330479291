import React from 'react';

import Duration from 'components/common/course/duration';

import './styles.less';

const SubjectMoreDetails = ({ courseName, stageName, duration }: any) => (
  <div className="subject-moredetails__content">
    <h3>{courseName}</h3>
    <h4>{stageName}</h4>
    <Duration>{duration} hours</Duration>
  </div>
);

export default SubjectMoreDetails;
