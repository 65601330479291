import React from 'react';

import { numberWithCommas } from 'utils';

import './styles.less';

interface PriceProps {
  price: number;
  discountedPrice?: number;
  className?: string;
}

const Price = (props: PriceProps) => {
  const { price, discountedPrice, className = '' } = props;

  const discounted = () => {
    if (discountedPrice && discountedPrice !== 0) {
      return (
        <span className="price">Rs. {numberWithCommas(discountedPrice)}</span>
      );
    }
    return null;
  };

  return (
    <div className={`price-container ${className}`}>
      {discounted()}
      <span className="price">Rs. {numberWithCommas(price)}</span>
    </div>
  );
};

export default Price;
