import React from 'react';
import { Alert } from 'antd';
import { AlertProps } from 'antd/lib/alert';

import './styles.less';

export default (props: AlertProps) => {
  const { message, type = 'success' } = props;

  if (message) {
    return (
      <Alert
        message={message}
        type={type}
        showIcon
        className="alert-message"
      />
    );
  }

  return null;
};
