import React, { useState } from "react";
import { Formik, Form as FormikForm, Field } from "formik";
import * as Yup from "yup";

import * as Form from "components/common/form";
import Alert from "components/common/alert";
import { useLocation, useHistory } from "react-router-dom";
import { UserAPI } from "api";
import RoutesRegistry from "services/router/registry";

const { Input, Button, TextArea } = Form;

const contactFormSchema = Yup.object().shape({
  password: Yup.string().required("Password is required."),
  confirmPassword: Yup.string().required("Confirm Password cannot be blank."),
});

const ResetPasswordForm = () => {
  const initialValues = { password: "", confirmPassword: "" };
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const history = useHistory();

  // console.log("token: ", token);
  return (
    <>
      <Alert message={errorMessage} type="error" />
      <Alert message={successMessage} type="success" />
      <Formik
        initialValues={initialValues}
        validationSchema={contactFormSchema}
        onSubmit={async (
          { password, confirmPassword },
          { setSubmitting, resetForm }
        ) => {
          try {
            setErrorMessage(null);
            setSuccessMessage(null);
            await UserAPI.resetPassword(
              token as string,
              password,
              confirmPassword
            );
            setSubmitting(false);
            resetForm();
            setSuccessMessage(
              "Password has been updated, please login to continue!"
            );
            window.location.href = RoutesRegistry.PwdResetSuccess.path;
          } catch (error) {
            setErrorMessage(
              error && error.response.data["message"]
                ? error.response.data["message"]
                : "Something went wrong. Please try again."
            );
            setSuccessMessage(null);
            console.error(error);
            setSubmitting(false);
          }
        }}>
        {({ errors, touched, isSubmitting }) => (
          <FormikForm noValidate>
            <Form.Item>
              <Field
                type="password"
                name="password"
                label="New Password*"
                disabled={isSubmitting}
                error={errors.password}
                touched={touched.password}
                as={Input}
              />
            </Form.Item>
            <Form.Item>
              <Field
                type="password"
                name="confirmPassword"
                label="Confirm New Password*"
                disabled={isSubmitting}
                error={errors.confirmPassword}
                touched={touched.confirmPassword}
                as={Input}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              className="action-btn"
              loading={isSubmitting}>
              SUBMIT
            </Button>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};

export default ResetPasswordForm;
