import React from 'react';
import { Input as ANTInput } from 'antd';
import { InputProps as ANTInputProps } from 'antd/lib/input';

import Icon from 'components/common/icon';

import './styles.less';

interface InputProps extends ANTInputProps {
  label?: string;
  field?: object;
  error?: string | undefined;
  touched?: boolean | undefined;
  correctAnswer?: number;
  userAnswer?: number;
}

const Radio = (props: InputProps) => {
  const {
    name,
    onChange,
    onBlur,
    value,
    label,
    disabled = false,
    className,
    checked,
    correctAnswer,
    userAnswer,
  } = props;

  return (
    <label
      className={`
        radio ${!disabled || 'disabled'}
        ${(correctAnswer === value) ? 'valid-answer' : ''}
        ${((correctAnswer !== value) && (userAnswer === value)) ? 'invalid-answer' : ''}
      `}
    >
      <ANTInput
        type="radio"
        name={name}
        className={`input ${className}`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        checked={checked}
      />
      {label}
      <Icon name="dot-circled" className="checked" />
      <Icon name="circle-empty" className="unchecked" />
    </label>
  );
};

export default Radio;
