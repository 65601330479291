import React, { useEffect, useState } from "react";
import { message } from "antd";

import './live_session_indicator.less'

type LiveSessionIndicatorType = {
    title: String,
    meeting_link: String
}

const disabledAlert = () => message.warn("You need to complete the payments to join the live session.");

const LiveSessionIndicator = (props: LiveSessionIndicatorType)=> {
    const { title, meeting_link } = props;
    var isMeetingLinkAvailable = false;

    if(meeting_link != '') {
        isMeetingLinkAvailable = true;
    }

    return(
        <div className="meeting-indicator">
            <p>{title} is live now.</p>
            {
                isMeetingLinkAvailable ? (<a href={`${meeting_link}`} target="_blank">Join Now</a>) : (<a onClick={() => disabledAlert()}>Join Now</a>)
            }
        </div>
    )
}

export default LiveSessionIndicator;