import RH from './request-handler';

interface SignIn {
  email: string;
  password: string;
}

interface SignUp extends SignIn {
  first_name: string;
  last_name: string;
  mobile_number: string
}

interface MemberProfile {
  password?: string;
  password_confirmation?: string;
  first_name?: string;
  last_name?: string;
  mobile_number?: string;
}

const signIn = async (payload: SignIn, visitorId: string) => {
  const res = await RH.post('/member/signin', payload, {
    headers: {
      'fingerprint-user-id': visitorId,
    },
  });
  return res;
};

const signUp = async (payload: SignUp, visitorId: string) => {
  const res = await RH.post('/member/signup', payload, {
    headers: {
      'fingerprint-user-id': visitorId,
    },
  });
  return res;
};

const forgotPassword = async (email: string) => {
  const res = await RH.get(`/member/password/reset?email=${email}`);
  return res.data;
};

const resetPassword = async (token: string,password: string,confirmPassword: string) => {
  const res = await RH.put(`/member/password/update?token=${token}&password=${password}&password_confirmation=${confirmPassword}`);
  return res.data;
};

const getMemberProfile = async () => {
  const res = await RH.get('/member/profile');
  return res.data;
};

const updateMemberProfile = async (payload: MemberProfile) => {
  const res = await RH.put('/member/profile', payload);
  return res.data;
};

const getMyCourses = async () => {
  const res = await RH.get('/member/dashboard/courses');
  return res.data;
};

const getMyOrders = async () => {
  const res = await RH.get('/member/dashboard/orders');
  return res.data;
};

export default {
  signIn,
  getMemberProfile,
  signUp,
  forgotPassword,
  resetPassword,
  updateMemberProfile,
  getMyCourses,
  getMyOrders,
};
