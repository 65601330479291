import React from 'react';
import { Spin, Icon } from 'antd';

import './styles.less';

interface SpinnerProps {
  size?: number;
  busy?: boolean;
}

const Spinner = (props: React.PropsWithChildren<SpinnerProps>) => {
  const { size = 28, busy, children } = props;

  if (children && !busy) {
    return <>{children}</>;
  }

  return <Spin className="spinner" indicator={<Icon type="loading" style={{ fontSize: size }} spin />} />;
};

export default Spinner;
