import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from 'antd';

import { UserAPI } from 'api';
import RequestHandler from 'api/request-handler';

import Header from 'components/common/header';
import Footer from 'components/common/footer';
import FSL from 'components/common/full-screen-loader';
import ScrollToTop from 'components/common/scroll-to-top';
import { defaultLang, Language } from 'components/common/language-selector';

import UserContainer from 'containers/auth';

import * as AuthService from 'services/auth';

import Routes from './services/router';

import packageJson from '../package.json';

const App = () => {
  const [user, setUser] = useState(null);
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    console.info(`v${packageJson.version}`);
    const initializeUser = async () => {
      try {
        const selectedLang = localStorage.getItem(defaultLang);

        RequestHandler.interceptors.request.use((config: any) => {
          config.params = config.params || {};
          config.params['lan'] = selectedLang ? selectedLang : Language.EN;
          return config;
        });
        const hasSession = await AuthService.initializeAuth();

        if (hasSession) {
          const res = await UserAPI.getMemberProfile();
          setUser(res.content.user);
        }

        setBusy(false);
      } catch (error) {
        setBusy(false);
      }
    };

    initializeUser();
  }, []);

  if (busy) {
    return <FSL />;
  }

  return (
    <UserContainer.Provider initialState={user}>
      <Router>
        <ScrollToTop>
          <Layout>
            <Header />
            <Routes />
            <Footer />
          </Layout>
        </ScrollToTop>
      </Router>
    </UserContainer.Provider>
  );
};

export default App;
